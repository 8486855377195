
const routes = [
    {
        path: "/announcement/list",
        component: () => import("@pages/announcement/AnnouncementList"),
        meta: {
            title: "通知&公告"
        }
    },
    {
        path: "/announcement/detail",
        component: () => import("@pages/announcement/AnnouncementDetail"),
        meta: {
            title: "通知&公告"
        }
    },
]

export default routes
