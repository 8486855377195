<!--  -->
<template>
  <div class="footer-inner">
    <div class="footer-content">
      <div class="top-content">
        <img src="@/assets/footer/logo.png" />
        <div>
          <el-link :underline="false" class="wechat">
            <img src="@/assets/footer/wechat.png" />
            <div class="img-container">
              <img src="@/assets/footer/wechat-er-code.png" />
            </div>
          </el-link>
          <el-link
            :underline="false"
            href="https://weibo.com/u/1341504357"
            target="_blank"
          >
            <img src="@/assets/footer/weibo.png" />
          </el-link>
        </div>
      </div>
      <div class="about-content">
        <el-row :gutter="24">
          <el-col :span="14" class="content-info">
            <div class="about-link">
              <p>解决方案</p>
              <ul>
                <li v-for="(i, idx) in Object.values(solutions)" :key="idx">
                  <el-link :underline="false" :href="i.path" target="_blank">
                    {{ i.footerLabel }}
                  </el-link>
                </li>
              </ul>
            </div>
            <div class="about-link">
              <p>产品</p>
              <ul>
                <li v-for="(i, idx) in Object.values(productions)" :key="idx">
                  <el-link :underline="false" :href="i.path" target="_blank">
                    {{ i.title }}
                  </el-link>
                </li>
              </ul>
            </div>
            <div class="about-link">
              <p>资源</p>
              <ul>
                <li v-for="(i, idx) in footerSources" :key="idx">
                  <el-link :underline="false" :href="i.href" target="_blank">
                    {{ i.label }}
                  </el-link>
                </li>
              </ul>
            </div>
            <div class="about-link">
              <p>公司</p>
              <ul>
                <li v-for="(i, idx) in footerCompanyInfos" :key="idx">
                  <el-link :underline="false" :href="i.href" target="_blank">
                    {{ i.label }}
                  </el-link>
                </li>
              </ul>
            </div>
            <div class="about-link">
              <p>战略合作伙伴</p>
              <ul class="img-link">
                <li v-for="(i, idx) in strategicPartners" :key="idx">
                  <el-link :underline="false">
                    <img :src="require('@/' + i.imgPath)" />
                  </el-link>
                </li>
              </ul>
            </div>
          </el-col>
          <el-col :span="7" :offset="3" class="contact-info">
            <p>联系方式</p>
            <div class="cooperation">
              <p>
                <span>客服电话</span>
                <span>400-030-8800 请按3</span>
                <span>（工作日09:00-18:00）</span>
              </p>
              <p>
                <span>商务合作</span>
                <span>021-61640673、021-61640693</span>
              </p>
            </div>
            <div class="filing-info">
              <div>
                <div>上海：</div>
                <div>
                  上海·中国(上海)虹口区东大名路1158号浦江国际金融广场23层03-05单元
                </div>
              </div>
              <div>
                <div>北京：</div>
                <div>
                  北京·中国(北京)海淀区东北旺西路中关村软件园二期(西扩)N-1、N-2地块新浪总部科研楼5层519室
                </div>
              </div>
              <p></p>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="icon-link">
        <!-- <img src="@/assets/footer/footer-logo.png" /> -->
        <el-link
          v-for="(i, idx) in footerPartners"
          :key="idx"
          :underline="false"
          :href="i.href"
          target="_blank"
        >
          <el-col class="footer-card">
            <img :src="require('@/assets/footer/' + i.src)" />
          </el-col>
        </el-link>
      </div>
      <div class="copyright">
        <div class="support-text">
          <p>
            Copyright&copy;2018-{{ date.getFullYear() }}. Powered by 新浪支付
            本网站支持<span class="ipv6">ipv6</span>
          </p>
        </div>
        <p>
          <img src="@/assets/footer/ba.png" />
          沪公网安备 31011502019576号 备案号 <a>京ICP备19004187号</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import {
  solutions,
  productions,
  footerSources,
  footerCompanyInfos,
  strategicPartners,
  footerPartners,
} from '@/util/enum'
export default {
  name: 'FooterComponent',

  setup() {
    const date = reactive(new Date())
    onMounted(() => {
      // console.log('footer mounted');
    })

    return {
      solutions,
      productions,
      footerSources,
      footerCompanyInfos,
      strategicPartners,
      footerPartners,
      date,
    }
  },
}
</script>

<style scoped lang="scss">
.footer-inner {
  height: 371px;
  margin: 15px 30px;

  .footer-content {
    width: auto;
    margin: 0 auto;

    .top-content {
      display: flex;
      width: 100%;
      height: 60px;
      flex-direction: row;
      justify-content: space-between;

      & > img {
        width: 128px;
        height: 40px;
      }

      .el-link img {
        width: 32px;
        height: 32px;
        margin-right: 15px;
      }

      .wechat {
        .img-container {
          position: absolute;
          top: -200px;
          left: 50%;
          transform: translateX(-50%);
          display: none;

          img {
            width: 200px;
            height: 200px;
          }
        }

        &:hover .img-container {
          display: block;
        }
      }
    }

    .about-content {
      //padding-top: 20px;
      border-top: 0.5px solid rgba($color: #e5e5e5, $alpha: 0.15);
      padding-bottom: 20px;
      border-bottom: 0.5px solid rgba($color: #e5e5e5, $alpha: 0.15);

      .content-info {
        margin-top: 40px;
        justify-content: space-between;
        display: flex;
        flex-direction: row;

        .about-link {
          display: inline-block;

          & > p {
            font-size: 14px;
            color: #fff;
            letter-spacing: 0;
            line-height: 14px;
            margin-bottom: 30px;
          }

          & > ul {
            list-style: none;
            padding: 0;

            & > li {
              margin-bottom: 15px;
              font-size: 12px;
            }
          }

          a {
            font-size: 12px;
            color: #606266;
            letter-spacing: 0;
            line-height: 14px;

            &:hover {
              text-decoration: underline;
            }
          }

          .img-link {
            li {
              margin-bottom: 8px;
              opacity: 0.5;

              &:hover {
                opacity: 1;
              }
            }

            a {
              display: inline-block;
              text-align: center;
              width: 90px;
              height: 26px;
              border: 1px solid #222222;
              border-radius: 3px;
              padding-top: 2px;
            }

            img {
              width: 90px;
              height: 24px;
            }
          }
        }
      }

      .contact-info {
        text-align: left;
        margin-top: 40px;
        display: block;

        & > p {
          font-size: 14px;
          color: #fff;
          letter-spacing: 0;
          line-height: 18px;
          margin-bottom: 30px;
        }

        .cooperation {
          p {
            line-height: 12px;

            & > span {
              font-size: 12px;
              color: #606266;
            }

            & > span:nth-child(2) {
              margin-right: 10px;
              margin-left: 10px;
            }
          }
        }

        .filing-info {
          margin-top: 30px;

          & > div {
            display: flex;
            flex-direction: row;
            font-size: 12px;
            color: #606266;
            letter-spacing: 0;
            line-height: 20px;
            margin-bottom: 5px;

            & > div:first-child {
              width: 44px;
            }

            & > div:nth-child(2) {
              width: 100%;
            }
          }
        }
      }
    }

    .icon-link {
      padding-top: 20px;
      margin-top: 22px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;

      & :global(.ant-card-body) {
        padding: 0;
      }

      img {
        width: 120px;
        height: 32px;

        &:first-child {
          height: 30px;
          margin-right: 15px;
        }
      }

      a {
        display: inline-block;
      }

      .footer-card {
        height: 32px;
        width: 120px;
        //background-repeat: no-repeat;
        //background-size: 100%;
        background-color: transparent;
        opacity: 0.5;
        border: none;

        &:hover {
          opacity: 1;
        }

        img {
          width: 120px;
          height: 32px;
        }
      }
    }

    .copyright {
      text-align: center;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      line-height: 12px;
      font-size: 12px;
      color: #a2a3a5;

      .ipv6 {
        color: #a2a3a5;
        border: 1px solid #a2a3a5;
        border-radius: 3px;
        padding: 0px 6px;
        margin-left: 3px;
        margin-right: 13px;
      }

      .support-text {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }

      & > p {
        a {
          font-size: 12px;
          color: #a2a3a5;
          letter-spacing: 0;
          line-height: 12px;

          &:hover {
            text-decoration: underline;
          }
        }

        img {
          width: 13px;
          height: 12px;
        }
      }
    }
  }
}

.el-col {
  display: flex;
}
</style>
