<!--  -->
<template>
    <div class='mobileFooter'>
      <van-cell v-for="(item) in data.cellLinks" is-link @click="clickItem(item)" :title="item.title" :key="item.path"/>
      <div class="telView">
        <div class="telItem">
          <img src="../../assets/mobile/bottom/dianhua@2x.png" alt="">
          <p>
            客服电话： <a :href=" 'tel:' + data.tel3">{{ data.tel3 }}</a>请按3 （工作日09:00-18:00）
          </p>
        </div>
        <div class="telItem"> 
          <img src="../../assets/mobile/bottom/hezuo@2x.png" alt="">
          <p>
            商务合作：<a :href="'tel:' + data.tel1">{{ data.tel1 }}</a>、<a :href=" 'tel:' + data.tel2">{{ data.tel2 }}</a>‬
          </p>
        </div>
        <div class="telItem">
          <p class="copyRight">
          Copyright©2018-{{data.year}}. Powered by 新浪支付 本网站支持
          </p>
        </div>
      </div>
    </div>
</template>

<script steup>
import { reactive } from 'vue';
export default {
  name : 'MobileFooter',
  setup() {
    let date = new Date();
    let data = reactive({
      year:date.getFullYear(),
      showBorder:false,
      tel1:"021-61640673",
      tel2:"021-61640693",
      tel3:"400-0308800",
      // 底部跳转链接
      cellLinks:[
        {title:"公司简介",path:"/mobile/aboutus"},
        {title:"联系我们",path:"/mobile/aboutus"},
        {title:"合作条款",path:"/mobile/agreement"},
        {title:"咨询公告",path:"/mobile/announcementList"},
      ],
    })

    const clickItem = (i) => {
      window.location.href = i.path + '?t=' + Date.now()
    }

    return {
      data,
      clickItem
    }
  }
}

</script>

<style scoped lang="scss">
.mobileFooter {
  width: 100%;
  background-color: #222222;
  padding-bottom: 55px;
  .van-cell {
    background-color: #222222;
    color: rgba($color: #ffffff, $alpha: 0.4) ;

  }
}
.telView {
  margin:30px 0;
  padding: 0 20px;

  .telItem {
    display: flex;
    margin-top: 20px;
    overflow: hidden;
    color: #FFFFFF;
    img {
        width: 16px;
        height: 16px;
        padding: 0 2px;
    }
    p {
      text-align: left;
      font-size: 12px;
      margin-left: 1px;

      a {
        color: #FFFFFF;
      }
    }
  }
  .copyRight {
    font-size: 10px;
    color: #A2A3A5;
  }
}
</style>