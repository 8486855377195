<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="common">
    <span v-bind='fadeLeft'>接入指引</span>
    <div class="guide-container">
      <el-row :gutter="24" justify="center">
        <el-col :span="4">
          <div class="step-container-1">
            <step-number step="1" v-bind='fadeLeft' />
            <div class="line-container-1" v-bind='fadeLeft'>
              <span />
              <span />
              <span>
                <img src="@/assets/home/arrow-right.png" />
              </span>
            </div>
            <div class="guide-content-1" v-bind='fadeLeft'>
              <div class="title-container-1">
                <span class="title">登录微钱包</span>
                <span class="parallelogram">企业版</span>
                <el-link :underline="false" href='https://e.pay.sina.com.cn/site/login' target="_blank">
                  登录
                </el-link>
              </div>
              <div class="content">
                还没有注册？
                <el-link :underline="false" href="https://e.pay.sina.com.cn/web/register">
                  立即注册
                  <img src="@/assets/productCenter/circle-arrow-right.png" />
                </el-link>
                <br />
                商务合作热线: 021-61640673
                <br />
                021-61640693
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="step-container-2 step-border">
            <step-number step="2" v-bind="fadeLeftDelay(200)" />
            <div class="line-container-2" v-bind='fadeLeft'>
              <span />
              <span />
              <span />
              <span />
              <span>
                <img src="@/assets/home/arrow-right.png" />
              </span>
            </div>
            <div class="guide-content-2">
              <div v-bind="fadeLeftDelay(150)">
                <div class="title-container-2">
                  <span class="title">上传公司资料</span>
                </div>
                <div class="content">上传营业执照、法人身份证和联系方式等；填写必要信息并提交审核资料。</div>
              </div>
              <div v-bind="fadeLeftDelay(300)">
                <div class="title-container-2">
                  <span class="title">材料审核</span>
                </div>
                <div class="content">我们会在1-2个工作日内完成审核，审核通过并开通后续服务！</div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="step-container-2">
            <step-number step="3" v-bind="fadeLeftDelay(450)" />
            <div class="line-container-2" v-bind='fadeLeft'>
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <div class="guide-content-2">
              <div v-bind="fadeLeftDelay(350)">
                <div class="title-container-2">
                  <span class="title">选择产品</span>
                </div>
                <div class="content">请在新浪支付选择适合您的产品并完成相关协议的签署。</div>
              </div>
              <div v-bind="fadeLeftDelay(500)">
                <div class="title-container-2">
                  <span class="title">专人对接，指导上线</span>
                </div>
                <div class="content">完成协议签署后，我们会有专业的团队与您对接，并开发产品上线。</div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="condition" v-bind='fadeLeft'>
      <span>申请条件</span>
      <div>
        <div class="item">
          <img src="@/assets/productCenter/1.png" />
          <span>企业合法经营，证件齐全</span>
        </div>
        <div class="item" v-bind="fadeLeftDelay(200)">
          <img src="@/assets/productCenter/2.png" />
          <span>有经营网站（需提供ICP备案许可，含H5和微信公众号一类）或APP（APP已上架，以及功能说明）</span>
        </div>
        <div class="item" v-bind="fadeLeftDelay(400)">
          <img src="@/assets/productCenter/3.png" />
          <span>服务器在中国大陆地区部署</span>
        </div>
        <el-link :underline="false" href='https://e.pay.sina.com.cn/web/register' target="_blank" v-bind="fadeLeftDelay(500)">
          立即接入
        </el-link>
      </div>
    </div>
  </div>
</template>
    
<script>
import StepNumber from '@c/StepNumber.vue'
import { fadeLeft, fadeLeftDelay } from '@/util/aos-constants'
export default {
  data() {
    return { imgPath: this.iconSrc, fadeLeft, fadeLeftDelay }
  },
  props: {
    step: {
      type: String,
      default: () => '1'
    }
  },
  components: { StepNumber }
}
</script>
    
<style scoped lang="scss">
.common {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 80px 0 80px;

  &>span {
    height: 40px;
    font-size: 40px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #181A1F;
    line-height: 40px;
    align-items: center;

    &::after {
      content: '';
      width: 70px;
      height: 6px;
      display: block;
      background: #DF001F;
      margin-top: 30px;
      margin-bottom: 20px;
      margin: 30px auto 20px auto;
    }
  }
}

.guide-container {
  margin-top: 60px;
  // padding: 100px auto 0 auto;
  width: 100%;
  // min-height: 300px;
  display: flex;
  // flex-direction: row;
  justify-content: center;
  padding: 20px;

  .el-col {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .title {
    height: 20px;
    font-size: 20px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #181A1F;
    line-height: 20px;
  }

  .content {
    margin-top: 15px;
    font-size: 15px;
    line-height: 28px;
    color: #5C5E62;

    .el-link {
      text-align: center;

      //width: 40px;
      // height: 20px;
      font-size: 16px;
      color: #4869D9;

      img {
        padding-left: 5px;
        width: 13px;
        height: 13px;
      }
    }
  }

  .step-container-1 {
    width: 100%;
    height: 100%;

    .line-container-1 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 30px;
      margin: 15px 0;

      &>span:nth-of-type(2) {
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 4px solid #DF001F;
        border-radius: 50%;
      }

      &>span:nth-of-type(1),
      &>span:nth-of-type(3) {
        content: "";
        width: calc(50% - 5px);
        height: 2px;
        background-color: #D0D1D2;
      }

      &>span:nth-of-type(3) {
        img {
          position: relative;
          width: 20px;
          height: 30px;
          right: calc(-100% + 10px);
          top: -14px;
          z-index: 10;
        }
      }
    }
    .guide-content-1 {
      padding: 3px 10px 15px 0px;

      .title-container-1 {
        display: flex;
        align-items: center;
        justify-content: center;

        .parallelogram {
          display: inline-block;
          width: 49px;
          height: 18px;
          background-color: #4869D9;
          transform: skewX(-20deg);
          border-radius: 4px;
          color: #fff;
          font-size: 12px;
          font-weight: 400;
          // line-height: 12px;
          text-align: center;
          margin: auto 3px;
        }

        .el-link {
          text-align: center;
          border: 1px solid #181A1F;
          border-radius: 6px;
          width: 40px;
          height: 20px;
          font-size: 14px;
          color: #181A1F;
          font-weight: 400;
        }
      }
    }
  }

  .step-container-2 {
    width: 100%;
    height: 100%;

    .line-container-2 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 30px;
      margin: 15px 0;

      &>span:nth-of-type(2),
      &>span:nth-of-type(4) {
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 4px solid #DF001F;
        border-radius: 50%;
      }

      &>span:nth-of-type(1),
      &>span:nth-of-type(3),
      &>span:nth-of-type(5) {
        content: "";
        // width: calc(25% - 5px);
        height: 2px;
        background-color: #D0D1D2;
      }

      &>span:nth-of-type(1) {
        margin-left: 10px;
        width: calc(25% - 15px);
      }

      &>span:nth-of-type(3) {
        width: calc(50% - 10px);
      }

      &>span:nth-of-type(5) {
        // margin-right: 10px;
        width: calc(25% - 5px);
        img {
          position: relative;
          width: 20px;
          height: 30px;
          right: calc(-100% + 10px);
          ;
          top: -14px;
        }
      }
    }

    .guide-content-2 {
      width: 100%;
      display: flex;

      &>div {
        padding: 3px 10px 15px 10px;
        width: 50%;

        .title-container-2 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .step-border {
    border-left: 1px dashed #D0D1D2;
    border-right: 1px dashed #D0D1D2;
  }

}

.condition {
  width: 80%;
  border-radius: 10px;
  padding: 40px;
  background-image: url('@/assets/productCenter/bg-shenqing.jpg');
  margin-bottom: 118px;

  &>span {
    //width: 96px;
    height: 24px;
    font-size: 24px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #181A1F;
    line-height: 24px;
  }

  &>div {
    width: 100%;
    height: 120px;
    margin-top: 30px;
    display: flex;
    flex-direction: row;

    .item {
      position: relative;
      min-width: 20%;

      img {
        width: 37px;
        height: 68px;
        padding: 10px 3px;
        // position: absolute;
        // border: 1px dashed black;
      }

      span {
        top: 38px;
        left: 18px;
        z-index: 10;
        position: absolute;
        width: 113px;
        height: 60px;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        color: #3A3C40;
        line-height: 18px;
      }
    }

    .item:nth-of-type(2) {
      width: 400px;

      span {
        width: 320px;
      }
    }
  }

  .el-link {
    margin-top: 20px;
    text-align: center;
    background: #DF001F;
    border-radius: 4px;
    width: 160px;
    height: 50px;
    font-size: 14px;
    color: #FFFFFF;
  }
}
</style>
    