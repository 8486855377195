<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="base" :class="classObject">
    <img :src="require('@/' + this.iconSrc)" />
    <span>{{ name }}</span>
    <span>{{ desc }}</span>
    <img v-if="type == 'accountManagementAuthorization'" :src="require('@/' + this.bottomImgScr)" />
  </div>
</template>
    
<script>
export default {
  data() {
    return {
      classObject: {
        'account-management-strategy-container': this.type == 'accountManagementStrategy',
        'account-management-authorization-container': this.type == 'accountManagementAuthorization',
        'internet-finance-methods-container': this.type == 'internetFinanceMethods',
        'internet-finance-strategy-container': this.type == 'internetFinanceStrategy',
        'internet-finance-progressC-container': this.type == 'internetFinanceProgress',
        'electronic-commerce-pain-points-container': this.type == 'electronicCommercePainPoints',
        'electronic-commerce-advantages-container': this.type == 'electronicCommerceAdvantages',
        'fund-payment-pain-points-container': this.type == 'fundPaymentPainPoints',
        'fund-payment-advantages-container': this.type == 'fundPaymentAdvantages'
      }
    }
  },
  props: {
    type: {
      type: String,
      required: true,
      default: () => 'accountManagementStrategy'
    },
    name: {
      type: String,
      required: true,
      default: () => ''
    },
    desc: {
      type: String,
      required: true,
      default: () => ''
    },
    iconSrc: {
      type: String,
      required: true,
      default: () => 'assets/home/folder.png'
    },
    bottomImgScr: {
      type: String,
      default: () => ''
    }
  },

  components: {},
  computed: {
  },
  mounted() {
    console.log('product card render: ', this.type);
  },
  methods: {
    // ttt() {

    //   console.log('is hover:', this.isHover);
    // }
  }

}
</script>
    
<style scoped lang="scss">
.base {
  // border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 40px 0px 0px;
  width: 276px;
  text-align: center;

  &>span:nth-of-type(1) {
    color: #181A1F;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    margin: 30px 0;
  }

  &>span:nth-of-type(2) {
    width: 100%;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #5C5E62;
    line-height: 22px;
    margin-bottom: 30px;
  }
}

.account-management-strategy-container {
  &>img:nth-of-type(1) {
    width: 90px;
    height: 90px;
  }
}

.account-management-authorization-container {
  width: 420px;
  align-items: flex-start;
  text-align: left;
  background: #fff;

  &>img:nth-of-type(1) {
    width: 60px;
    height: 60px;
  }

  &>img:nth-of-type(2) {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
  }
}

.internet-finance-methods-container {
  &>img:nth-of-type(1) {
    width: 80px;
    height: 80px;
  }
}

.internet-finance-strategy-container {
  &>img:nth-of-type(1) {
    width: 90px;
    height: 90px;
  }
}

.internet-finance-progressC-container {
  width: 420px;
  align-items: flex-start;
  text-align: left;
  background: #fff;

  &>img:nth-of-type(1) {
    width: 60px;
    height: 60px;
  }
}

.electronic-commerce-pain-points-container {
  margin: 0px;
  width: calc(25% - 20px);

  &>img:nth-of-type(1) {
    width: 80px;
    height: 80px;
  }

  &>span:nth-of-type(2) {
    width: calc(100% - 30px);
  }
}

.electronic-commerce-advantages-container {
  width: calc(33% - 90px);
  border-radius: 24px;
  padding: 30px;
  align-items: flex-start;
  text-align: left;
  background: #F5F7F8;
  margin: 20px 30px 0px 0px;

  &>img:nth-of-type(1) {
    width: 48px;
    height: 48px;
  }

  &>span:nth-of-type(1) {
    color: #181A1F;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    margin: 20px 0;
  }

  &>span:nth-of-type(2) {
    width: 100%;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #5C5E62;
    line-height: 22px;
    margin-bottom: 0px;
  }
}

.fund-payment-pain-points-container {
  margin: 0px;
  width: calc(33% - 20px);

  &>img:nth-of-type(1) {
    width: 80px;
    height: 80px;
  }

  &>span:nth-of-type(2) {
    width: calc(100% - 30px);
  }
}

.fund-payment-advantages-container {
  width: calc(50% - 100px);
  border-radius: 24px;
  padding: 30px;
  align-items: flex-start;
  text-align: left;
  background: #F5F7F8;
  margin: 20px 40px 0px 0px;

  &>img:nth-of-type(1) {
    width: 48px;
    height: 48px;
  }

  &>span:nth-of-type(1) {
    color: #181A1F;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    margin: 20px 0;
  }

  &>span:nth-of-type(2) {
    width: 100%;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #5C5E62;
    line-height: 22px;
    margin-bottom: 0px;
  }
}
</style>
    