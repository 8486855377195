<template>
  <div class="announcement">
    <div class="banner">
      <img src="@/assets/announcement/banner-12.jpg" />
      <div v-bind="fadeLeft">
        <p>公告&通知</p>
        <el-input v-model="input" class="w-50 m-2" size="large" placeholder="请输入搜索关键词" :suffix-icon="Search"
          @change="inputChange" />
      </div>
    </div>

    <el-tabs :model-value="category" type="card" stretch v-bind="fadeLeft" @tab-change="tabChange">
      <el-tab-pane v-for="(i, idx) in tabs" :key="idx" :label="i.name" :name="i.id" />
    </el-tabs>

    <div class="list-container" v-bind="fadeLeft">
      <div v-for="(i, idx) in tips" :key="idx">
        <el-link :underline="false" :href="'/announcement/detail?id=' + i.id"
          target="_blank">
          {{ i.title }}
        </el-link>
        <span>{{ i.createTimeFormat }}</span>
      </div>
    </div>

    <el-pagination v-model:current-page="currentPage" v-model:page-size="pageSize" :page-sizes="[10, 30, 50]"
      layout="total, prev, pager, next, sizes, jumper" v-model:total="total" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" v-bind="fadeLeft" />

  </div>
</template>
     
<script setup>
import { ref } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { qrtNoticeTop, qryCategory } from "@/API/announcement"
import { fadeLeft } from '@/util/aos-constants'
import get from 'lodash-es/get';
const input = ref('')
const currentPage = ref(1)
const pageSize = ref(10)
const total = ref(10)
const tips = ref([])
const tabs = ref([])
const category = ref(0)

const queryNotice = () => {
  qrtNoticeTop({ pageNo: currentPage.value, pageSize: pageSize.value, title: input.value, categoryId: category.value })
    .then(res => {
      tips.value = get(res, 'data.noticeVoList', [])
      total.value = get(res, 'data.totalCount', 0)
      console.log('qrtNoticeTop res: ', res);
    })
}

qryCategory().then(res => {
  tabs.value = get(res, 'data.categoryList', [])
  category.value = tabs.value.length > 0 ? tabs.value[0].id : 0
  console.log('categoryList res: ', res);
})

const handleSizeChange = (val) => {
  console.log(`${val} items per page`)
  queryNotice()
}
const handleCurrentChange = (val) => {
  console.log(`current page: ${val}`)
  queryNotice()
}

const inputChange = (val) => {
  console.log(`input change: ${val}`)
  queryNotice()
}

const tabChange = (val) => {
  console.log(`tabChange change: ${val}`)
  category.value = val
  console.log(`category change: ${category.value}`)
  queryNotice()
}

</script>
     
<style scoped lang="scss">
.announcement {
  .banner {
    min-height: 350px;
    min-width: 1344px;
    width: 100%;
    height: calc(100vw * 100 / 384);
    position: relative;
    margin-bottom: 30px;

    img {
      position: absolute;
      width: 100%;
      min-width: 1344px;
      height: calc(100vw * 100 / 384);
      min-height: 350px;
      z-index: 10;
    }

    &>div {
      position: absolute;
      top: 150px;
      left: 200px;
      width: 400px;
      height: 200px;
      z-index: 20;
      background-color: transparent;
      max-width: 470px;
      display: flex;
      flex-direction: column;

      p {
        font-size: 40px;
        color: #FFFFFF;
        letter-spacing: 2px;
        text-align: left;
        line-height: 70px;
      }

      .el-input {
        height: 44px;
      }
    }
  }

  .list-container {
    width: 64%;
    margin: 30px 18%;

    &>div {
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #EEEEEE;

      .el-link,
      &>span {
        height: 16px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #3A3C40;
        line-height: 16px;
      }
    }
  }

  :deep(.el-input__wrapper) {
    background-color: transparent;
  }

  :deep(.el-tabs__nav-scroll) {
    width: 50%;
    padding: 0 25%;
  }

  :deep(.el-tabs__item.is-active) {
    color: #181A1F;
    // color:red;
    background-color: #fff;
    border-bottom: 1px solid #F4F4F4;
  }

  :deep(.el-tabs__item) {
    color: #5C5E62;
    background-color: #F4F4F4;
  }

  :deep(.el-tabs--card>.el-tabs__header) {
    border-bottom: none;
  }

  .el-pagination {
    margin: 30px auto;
    display: flex;
    justify-content: center;
  }

  :deep(.el-pagination) {
    width: 40%;
    padding: 0 auto;
  }
}
</style>