<!--  -->
<template>
    <div class='LookBigImage'>
      <img :src="data.imageURL">
    </div>
</template>

<script steup>
import { useRoute } from 'vue-router'

export default {
  name:'LookBigImage',
  setup() {
    const route = useRoute()
    let data = {
      imageURL:route.query.imageURL,
    }
    console.log("route:",route)
    return {
      data
    } 
  }
}

</script>

<style scoped lang='scss'>
.LookBigImage {
  width: 100%;
  min-height: 600px;

  img {
    width: 100%;
  }
}
</style>