<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="container">
    <img src="@/assets/home/tips.png" />
    <div>
      <el-link :underline="false" v-for="(i, idx) in filterTips" :key="idx" :href="'/announcement/detail?id=' + i.id" target="_blank">
        {{ i.title }}
      </el-link>
    </div>

    <el-link :underline="false" href='/announcement/list' target="_blank">
      更多公告
      <el-icon class="el-icon--right"><arrow-right /></el-icon>
    </el-link>
  </div>
</template>

<script>
import { ArrowRight } from '@element-plus/icons-vue'
export default {
  props: {
    tips: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  components: { ArrowRight },
  computed: {
    filterTips() {
      return this.tips.length < 4? this.tips : this.tips.slice(0, 3)
    }
  },
  mounted() {
    console.log('banner render');
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 50px;
  background-color: #121724;
  opacity: 0.7;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #fff;

  &>img {
    width: 22px;
    height: 18px;
    margin-left: 200px;
  }

  &>div {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    &>.el-link {
      color:#fff;
      min-width: 160px;
      margin-left: 30px;
    }
  }

  &>.el-link:last-child {
    color:#fff;
    margin-right: 200px;
    width: 160px;
  }
}
</style>
