<!--  -->
<template>
  <div class='accountServiceProduct'>
    <TopHoverView 
      hoverTextViewTop="220px"
      :bannerURL="data.bannerURL"  
      titleText="账户服务产品" 
      describeText="完善的账户体系，丰富的账户类型，满足全场景的账户功能需求"/>
    <UseGuideView :imageURL="data.accountSystem"   marginTop="0px"/>
    <UseGuideView :imageURL="data.accountUserInfo" marginTop="0px"/>
    <UseGuideView :imageURL="data.accountServiceAdvantages" marginTop="5px"/>
    <div class="margin-horizontal">
      <UseGuideView marginBottom="30px" />
    </div>
  </div>
</template>

<script steup>
import TopHoverView from '../payment/components/TopHoverView'
import UseGuideView from '../payment/components/UseGuideView'
  export default {
    name: 'AccountServiceProduct',
    components:{TopHoverView,UseGuideView},
    setup() {
    let data = {
      bannerURL:require('@/assets/mobile/accountService/account-servicebanner@2x.png'),
      accountSystem:require('@/assets/mobile/accountService/account-system@2x.png'),
      accountUserInfo:require('@/assets/mobile/accountService/account-userinfo@2x.png'),
      accountServiceAdvantages:require('@/assets/mobile/accountService/account-serviceadvantages@2x.png'),
 
    }
    return {
      data
    };
  }

  }
</script>

<style scoped lang="scss">
.accountServiceProduct {
  .margin-horizontal {
    padding: 0px 15px;
  }
}
</style>