
const routes = [
  {
    path: "/product/standardPayment",
    name: 'standardPayment',
    component: () => import("@pages/productCenter/StandardPayment"),
    meta: {
      title: '标准支付产品',
    },
  },
  {
    path: "/product/accountHubPlatform",
    name: 'accountHubPlatform',
    component: () => import("@pages/productCenter/AccountHubPlatform"),
    meta: {
      title: '账户中台产品',
    },
  },
  {
    path: "/product/accountServices",
    name: 'accountServices',
    component: () => import("@pages/productCenter/AccountServices"),
    meta: {
      title: '账户服务产品',
    },
  }
]

export default routes
