<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div :class="classObject" @mouseover="type == 'product' ? this.imgPath = this.iconHoverSrc : {}" @mouseout="type == 'product' ? this.imgPath = this.iconSrc : {}">
    <img v-if="type != 'progress'" :src="require('@/' + this.imgPath)" />
    <div v-if="type == 'progress'">
      <span>{{ idx }}</span>
      <img :src="require('@/' + this.imgPath)" />
    </div>
    <span>{{ title }}</span>
    <span>{{ subTitle }}</span>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      imgPath: this.iconSrc,
      classObject: {
        'product-container': this.type == 'product',
        'serve-container': this.type == 'serve',
        'progress-container': this.type == 'progress'
      }
    }
  },
  props: {
    type: {
      type: String,
      required: true,
      default: () => 'product'
    },
    title: {
      type: String,
      required: true,
      default: () => ''
    },
    subTitle: {
      type: String,
      required: true,
      default: () => ''
    },
    iconSrc: {
      type: String,
      required: true,
      default: () => 'assets/home/folder.png'
    },
    iconHoverSrc: {
      type: String,
      default: () => 'assets/home/folder-hover.png'
    },
    idx: {
      type: Number,
      default: () => 1
    },
  },

  components: {},
  computed: {
  },
  mounted() {
    // console.log('product card render: ', this.type);
  },
  methods: {
   
  }

}
</script>
  
<style scoped lang="scss">
.product-container {
  width: 280px;
  height: 280px;
  background: #F5F7F8;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
  padding: 0 40px;
  text-align: center;

  &:hover {
    box-shadow: 0px 30px 40px 0px rgba(223, 0, 31, 0.08);
    border: 2px solid #DF001F;
    background: #FFFFFF;

    &>span:nth-of-type(1) {
      color: #DF001F
    }
  }

  &>img {
    width: 64px;
    height: 64px;
  }

  &>span:nth-of-type(1) {
    color: #181A1F;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    margin: 30px 0 15px 0;
  }

  &>span:nth-of-type(2) {
    font-size: 14px;
    color: #5C5E62;
    line-height: 22px;
  }
}

.serve-container {
  width: 270px;
  height: 200px;
  background: #fff;
  box-shadow: 0px 50px 50px 0px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  padding: 10px 40px;

  &>img {
    width: 64px;
    height: 64px;
  }

  &>span:nth-of-type(1) {
    color: #181A1F;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    margin: 30px 0 15px 0;
  }

  &>span:nth-of-type(2) {
    font-size: 14px;
    color: #5C5E62;
    line-height: 22px;
  }
}

.progress-container {
  width: 200px;
  height: 220px;
  background: #fff;
  box-shadow: 0px 50px 50px 0px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  padding: 20px;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &>span {
      font-size: 60px;
      line-height: 70px;
      font-weight: bold;
      background: linear-gradient(180deg, rgba(223, 0, 31, 0.8) 0%, rgba(223, 0, 31, 0) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &>img {
      width: 48px;
      height: 48px;
    }
  }

  &>span:nth-of-type(1) {
    color: #181A1F;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    margin: 30px 0 15px 0;
  }

  &>span:nth-of-type(2) {
    font-size: 14px;
    color: #5C5E62;
    line-height: 22px;
  }
}
</style>
  