import axios from 'axios'
import qs from 'qs'
import { ElMessage } from 'element-plus'
import get from 'lodash-es/get';



// 创建axios 实例
const service = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? '/mock' : '', // api的base_url
  timeout: 10000 // 请求超时时间
})

// 添加请求拦截器
service.interceptors.request.use(function (config) {
  const ajaxUrl = config.url
  // // 以下接口允许不检验token
  const filterUrl = [
    '/front/h5/log'
  ]
  // 以下接口 Content-Type = application/json
  const jsonUrl = [
    '/api/notice/qryCategory',
    '/api/notice/qryNoticeDetail',
    '/api/notice/top'
  ]

  // /*
  //   ！！！ 特别警示：
  //   在header头里增加任何信息时，请匆必衡量，是否加到下面的 【config.url.indexOf('markData/report/js/upload')<0】 条件里，否则所有埋点上报，均会失败
  // */
  if (jsonUrl.includes(ajaxUrl)) {
    config.headers['Content-Type'] = 'application/json'
    if (config.method === 'post') {
      config.data = JSON.stringify(config.data)
    }
  } else {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    if (config.method === 'post') {
      config.data = qs.stringify({
        ...config.data
      })
    }
  }
  // let __token = store.state.token
  // if (!__token && !filterUrl.indexOf(ajaxUrl) !== -1) {
  //   __token = window.WJ.Cookie.get('x-passport-token')
  // }
  // __token && (config.headers['x-passport-token'] = __token)
  // if (config.url.indexOf('markData/report/js/upload') < 0) {
  //   config.headers['xiaodai-env'] = process.env.VUE_APP_ENV
  //   config.headers['xiaodai-version'] = store.state.xiaodaiVersion || '5.4.0-RELEASE'// 线上删除
  //   config.headers['x-passport-channel'] = store.state.userData.channel || 'weibo'
  //   config.headers['x-passport-pCode'] = store.state.userData.productCode || ''
  //   if (ajaxUrl.includes('/xiaodaiapi')) { // 调用xiaodai接口
  //     config.headers['x-wejoydata-brand-name'] = store.state.userData.jieqianToken.b || ''
  //     config.headers['x-wejoydata-channel'] = 'weibo'
  //     config.headers['x-wejoydata-sku'] = 'cycle_loan'
  //     config.headers['x-wejoydata-source'] = '000007001'
  //     config.headers['x-wejoydata-subsource'] = store.state.userData.jieqianToken.sse || ''
  //   }
  // }
  //  else {
  //   // 追加时间戳，防止请求缓存问题
  //   // config.url = ajaxUrl + (ajaxUrl.indexOf('?') !== -1 ? '&' : '?') + 't=' + Date.now()
  // }
  return config
}, function (error) {
  return Promise.reject(error)
})

// 添加响应拦截器
service.interceptors.response.use(function (response) {
  // -2001, "token校验异常",
  // -2002, "生成token异常",
  // -2003, "用户token过期",
  // -2004, "该token被登出",
  // -2005, "AES密钥失效，需重新协商",

  // const tokenCheck = response.status === 200 && response.data.control && response.data.control.error === -2001
  // const tokenCreate = response.status === 200 && response.data.control && response.data.control.error === -2002
  // const tokenExpire = response.status === 200 && response.data.control && response.data.control.error === -2003
  // const tokenLogout = response.status === 200 && response.data.control && response.data.control.error === -2004
  // const tokenLose = response.status === 200 && response.data.control && response.data.control.error === -2005
  // // 如果是从三方进来的话，token 失效返回上一页
  // if (store.state.commonServiceInfo && store.state.commonServiceInfo.bizNo) {
  //   if (tokenCheck || tokenCreate || tokenExpire || tokenLose || tokenLogout) {
  //     store.commit('setToken', '')
  //     window.history.go(-1)
  //     return response
  //   }
  // } else {
  //   // 金融账户本身token失效
  //   if (tokenCheck || tokenCreate || tokenExpire || tokenLose || tokenLogout) {
  //     // 到拦截页的数据监控
  //     store.commit('setToken', '')
  //     sessionStorage.removeItem('token')
  //     window.WJ.Cookie.del('x-passport-token')
  //     router.replace('/noToken?token=' + response.data.control.error)
  //     return response
  //   } else {
  //     // 更新服务器时间
  //     if (response.data.control && response.data.control.serverTime) {
  //       store.commit('setServerTime', Number(response.data.control.serverTime))
  //     }
  //   }
  // }

  if (get(response, 'data.head.code') == '200') {
    return response.data.body
  } else {
    ElMessage({
      message: get(response, 'data.head.msg'),
      type: 'error',
      duration: 5 * 1000,
    })
  }

}, function (error) {
  if (error.message.includes('timeout')) {
    console.log('请求超时哦')
    if (process.env.NODE_ENV != 'production') {
      ElMessage({
        message: "接口请求超时",
        type: 'error',
        duration: 5 * 1000,
      })
    }
  }
  return Promise.reject(error)
})

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function Get(url, params = {}) {
  console.log('【GET】url: ', url, ' params: ', params);
  return new Promise((resolve, reject) => {
    service.get(url, {
      params: params
    })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function Post(url, data = {}) {
  console.log('【POST】url: ', url, ' params: ', data, '\nNODE_ENV:', process.env.NODE_ENV);
  return new Promise((resolve, reject) => {
    if (process.env.NODE_ENV == 'development') {
      // 测试环境走mock数据
      service.get(url, data)
        .then(response => {
          console.log('post data: ', response);
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    } else {
      service.post(url, data)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    }
  })
}
