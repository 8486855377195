import MobileLayout from "@/layout/Mobile/MobileLayout"
const routes = {
  path: "/mobile",
  component: MobileLayout,
  children:[
    {
      path: "",
      component: () => import("@pages/mobile/home"),
      meta: {
        title: "首页",
      },
    },
    {
      path: "accountManagement",
      component: () => import("@mobile/solutions/AccountManagement"),
      meta: {
        title: "企业级账户管理解决方案",
      },
    },
    {
      path: "electronicCommerce",
      component: () => import("@mobile/solutions/ElectronicCommerce"),
      meta: {
        title: "电商行业",
      }
    },
    {
      path: "internetFinance",
      component: () => import("@mobile/solutions/InternetFinance"),
      meta: {
        title: "互联网金融",
      },
    },
    {
      path: "fundPayment",
      component: () => import("@mobile/solutions/FundPayment"),
      meta: {
        title: "基金支付",
      },
    },
    {
      path: "standardpayment",
      component: () => import("@mobile/payment/StandardPayment"),
      meta: {
        title: "标准支付产品",
      },
    },
    {
      path: "accountmiddleproduct",
      component: () => import("@mobile/accountmiddle/AccountMiddleProduct"),
      meta: {
        title: "账户中台产品",
      },
    },
    {
      path: "accountserviceproduct",
      component: () => import("@mobile/accountService/AccountServiceProduct"),
      meta: {
        title: "账户服务产品",
      },
    },
    {
      path: "aboutus",
      component: () => import("@mobile/aboutus/AboutUS"),
      meta: {
        title: "关于我们",
      },
    },
    {
      path: "agreement",
      component: () => import("@mobile/agreement/AgreementList"),
      meta: {
        title: "合作协议",
      },
    },
    {
      path: "announcementList",
      component: () => import("@mobile/announcement/AnnouncementList"),
      meta: {
        title: "合作协议",
      },
    },
    {
      path: "announcementDetail",
      component: () => import("@mobile/announcement/AnnouncementDetail"),
      meta: {
        title: "合作协议",
      },
    },
    {
      path: "lookbigimage",
      component: () => import("@pages/home/LookBigImage"),
      meta: {
        title: "新浪支付",
      },
    }
  ]
}
export default routes;