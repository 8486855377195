import { createRouter, createWebHistory } from "vue-router";
import WebLayout from "@/layout/WebLayout";
import products from "@/router/productCenter";
import solutions from "@/router/solutions";
import announcements from "@/router/announcements"
import mobileRouter from './mobile';

import { isMobile } from '@/util/tool'

const routes = [
  {
    path: "/",
    name: "Home",
    component: WebLayout,
    children: [
      {
        path: '/',
        redirect: '/home'
      },
      {
        path: "/about",
        component: () => import("@pages/about/About"),
        meta: {
          title: "关于我们",
        },
      },
      {
        path: "/home",
        component: () => import("@pages/home/Home"),
        meta: {
          title: "新浪支付",
        },
      },
      {
        path: "/collaboration",
        component: () => import("@pages/collaboration/Collaboration"),
        meta: {
          title: "签约合作"
        }
      },
      {
        path: "/agreement",
        component: () => import("@pages/agreement/Agreement"),
        meta: {
          title: "关于我们",
        },
      },
      ...announcements,
      ...products,
      ...solutions,
      {
        path: '/:pathMatch(.*)*',
        name: 'notFound',
        redirect: isMobile() ? '/mobile' : '/home'
      }
    ],
  },
  {
    path: "/lookbigimage",
    name: "LookBigImage",
    component: () => import("@pages/home/LookBigImage"),
    meta: {
      title: "新浪支付",
    },
  },
  mobileRouter
];


const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  // 滚动位置到页面顶部
  window.scrollTo(0, 0);
  next();
})

router.afterEach((to, from) => {
  document.title = to.meta.title
})
export default router