<template>
  <div class="account-hub-platform">
    <top-banner :title="accountHubPlatform.title" :subTitle="accountHubPlatform.subTitle"
      :bgSrc="accountHubPlatform.bgSrc" />
    <div class="common">
      <span v-bind="fadeLeft">会员共享解决方案，打破业务壁垒</span>
      <div class="card-container">
        <div v-for="(i, idx) in accountHubPlatformMemberPlans" :key="idx">
          <account-card :name="i.name" :icon-src="i.iconSrc" v-bind="fadeLeftDelayIndex(idx)"/>
          <img src="@/assets/productCenter/accountHubPlatform/arrow-up-red.png" data-aos="fade-up" data-aos-delay="1000"/>
        </div>
        <div class="bottom" data-aos="fade-up" data-aos-delay="1100">
          <img src="@/assets/productCenter/accountHubPlatform/5.png" />
          <span>大型企业集团底层信息</span>
        </div>
      </div>
    </div>
    <div class="common advantage">
      <span v-bind="fadeLeft">账户中台产品优势</span>
      <div class="card-container">
        <payment-card v-for="(i, idx) in accountHubPlatformAdvantages" :key="idx" :title="i.title" :sub-title="i.desc"
          :icon-src="i.iconSrc" :is-advantage="true" v-bind="fadeLeftDelayIndex(idx, 200)"/>
      </div>
    </div>
    <integration-guide />
  </div>
</template>
  
<script setup>
import TopBanner from '@c/TopBanner.vue';
import PaymentCard from './components/PaymentCard.vue';
import IntegrationGuide from '@c/IntegrationGuide.vue';
import AccountCard from './components/AccountCard.vue';
import { productions, accountHubPlatformMemberPlans, accountHubPlatformAdvantages} from '@/util/enum'
import { fadeLeft, fadeLeftDelayIndex } from '@/util/aos-constants'
const accountHubPlatform = productions['accountHubPlatform']

</script>
  
<style scoped lang="scss">
.account-hub-platform {
  .common {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 80px 0 80px;

    &>span {
      height: 40px;
      font-size: 40px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #181A1F;
      line-height: 40px;
      align-items: center;

      &::after {
        content: '';
        width: 70px;
        height: 6px;
        display: block;
        background: #DF001F;
        margin-top: 30px;
        margin-bottom: 20px;
        margin: 30px auto 20px auto;
      }
    }

    .card-container {
      margin-top: 60px;
      width: 1280px;
      display: flex;
      // flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 20px;

      &>div {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        img {
          width: 24px;
          height: 32px;
        }
      }

      &div::after {
        content: '';
        width: 70px;
        height: 6px;
        display: block;
        background: #DF001F;
        margin-top: 30px;
        margin-bottom: 20px;
        margin: 30px auto 20px auto;
      }

      .bottom {
        width: 1240px;
        height: 200px;
        margin: 20px 20px 100px 20px;
        background: #F5F7F8;
        border-radius: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        img {
          width: 120px;
          height: 141px;
        }

        span {
          width: 240px;
          height: 24px;
          font-size: 24px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #181A1F;
          line-height: 24px;
        }
      }
    }
  }

  .advantage {
    padding: 80px auto 40px auto;
    background: #F5F7F8;

    .card-container {
      margin-top: 0px;
    }
  }
}
</style>