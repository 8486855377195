<!--  -->
<template>
    <div class='AgreementListItem'>
      <p>{{item.content}}</p>
      <div class="imageContainer" :class="showBottomLine? 'addBottomLine':'noneBottomLine'">
        <a :href="item.url? item.url : '/' + item.fileEncodeName" target="_blank">
          <img class="imgItem" :src="require('@/assets/mobile/agreement/look@2x.png')" alt="" >
        </a>
        <a :href="'/' + item.fileEncodeName" :download="item.filename" v-if="item.isDownload">
          <img class="imgItem" :src="require('@/assets/mobile/agreement/download@2x.png')" alt="">
        </a>
      </div>
      
    </div>
</template>

<script steup>
export default {
  name : 'AgreementListItem',
  props:{
    showBottomLine:{
      type:Boolean,
      default:true,
    },
    item:{
      type:Object,
      required:true,
    }
  },
  setup(props,context) {
    function clickEvent(index){
      context.emit('clickEvent',index)
    }
    return {
      clickEvent,
    }
  }
}

</script>

<style scoped lang="scss">
.AgreementListItem {
  padding-top: 27px;
  padding-left:29px;
  padding-right:29px;

  p {
    text-align: left;
    font-size: 16px;
    font-family: MiSans-Medium, MiSans;
    font-weight: 500;
    color: #181A1F;
  }

  .addBottomLine {
    border-bottom: 0.5px solid #EEEEEE;
  }

  
  
  .noneBottomLine {
    border-bottom: none;
  }
}

.imageContainer {
  display: flex;
  .imgItem {
    width: calc((100vw - 38px - 40px)/2);
    min-height:25px;
  }
  a {
    margin: 15px 10px;
  }
  a:first-of-type {
    margin-left: 0;
  }
  a:not(:first-of-type) {
    margin-right: 0;
  }

  
  
}

</style>