<template>
  <div class="electronic-commerce">
    <top-banner :title="electronicCommerce.title" :subTitle="electronicCommerce.desc" :bgSrc="electronicCommerce.bgSrc" />
    <div class="middle-container">
      <div>
        <h3 v-bind="fadeLeft">行业现状及痛点</h3>
        <div class="content pain-points">
          <solution-card v-for="(i, idx) in electronicCommercePainPoints" :key="idx" type="electronicCommercePainPoints"
            :name="i.title" :desc="i.desc" :icon-src="i.iconSrc" :class="{ 'right-border': idx != 3 }"
            v-bind="fadeLeftDelayIndex(idx)" />
        </div>
      </div>
    </div>
    <div class="middle-container">
      <div>
        <h3 v-bind="fadeLeft">我们的优势</h3>
        <div class="content advantages">
          <solution-card v-for="(i, idx) in electronicCommerceAdvantages" :key="idx" type="electronicCommerceAdvantages"
            :name="i.title" :desc="i.desc" :icon-src="i.iconSrc" v-bind="fadeLeftDelayIndex(idx % 3)" />
        </div>
      </div>
    </div>
    <solution-introduction img-src="assets/solutions/electronicCommerce/electronicCommerce.png"
      desc="针对平台上用户的账户预充值场景，新浪支付可提供标准化的线上支付服务，通过收银台方式统一接入，用户可先通过绑定银行卡或通过网银为其账户充值，使用账户资金进行付款。" />
    <integration-guide />
  </div>
</template>
 
<script setup>
import TopBanner from '@c/TopBanner.vue';
import IntegrationGuide from '@c/IntegrationGuide.vue';
import SolutionCard from './components/SolutionCard.vue';
import SolutionIntroduction from './components/SolutionIntroduction.vue';
import { solutions, electronicCommercePainPoints, electronicCommerceAdvantages } from '@/util/enum'
import { fadeLeft, fadeLeftDelayIndex } from '@/util/aos-constants'
const electronicCommerce = solutions['electronicCommerce']
</script>
 
<style scoped lang="scss">
.electronic-commerce {
  .middle-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

    &>div {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
      width: calc(100% - 500px);
      max-width: 1200px;
      height: 100%;
      padding: 40px;
      border-left: 1px solid #E6E6E9;
      border-right: 1px solid #E6E6E9;

      &>h3 {
        height: 40px;
        font-size: 40px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #181A1F;
        line-height: 40px;
      }

      .content {
        display: flex;
        flex-direction: row;
        padding-top: 40px;
        padding-left: 20px;
        flex-wrap: wrap;
      }

      .pain-points {
        justify-content: space-between;

        .right-border {
          border-right: 1px solid #E6E6E9;
        }
      }

      .advantages {
        justify-content: flex-start;
      }
    }
  }
}
</style>