<!--  -->
<template>
    <div class='accountMiddleProduct'>
      <TopHoverView 
      hoverTextViewTop="250px"
      :bannerURL="data.bannerURL"  
      titleText="账户中台产品" 
      describeText="解决同人不同户的数据隔离痛点，提高集团型企业的会员共享效率"/>
    
      <UseGuideView :imageURL="data.memberSolution"   marginTop="0px"/>
      <UseGuideView :imageURL="data.middleAdvantages" marginTop="5px"/>
      <div class="margin-horizontal">
        <UseGuideView marginBottom="25px" />
      </div>
    </div>
</template>

<script steup>
import TopHoverView from '../payment/components/TopHoverView'
import UseGuideView from '../payment/components/UseGuideView'

export default {
  name : 'AccountMiddleProduct',
  components:{TopHoverView,UseGuideView},
  setup() {
    let data = {
      bannerURL:require('@/assets/mobile/accountMiddle/account_banner@2x.png'),
      memberSolution:require('@/assets/mobile/accountMiddle/account_membersolution@2x.png'),
      middleAdvantages:require('@/assets/mobile/accountMiddle/account_middleadvantages@2x.png'),
    }
    return {
      data
    };
  }
}

</script>

<style scoped lang="scss">
.accountMiddleProduct {
  .margin-horizontal {
    padding: 0px 15px;
  }
}


</style>