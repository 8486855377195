<template>
  <div class="account-services">
    <top-banner :title="accountServices.title" :subTitle="accountServices.subTitle" :bgSrc="accountServices.bgSrc" />
    <div class="common">
      <span v-bind="fadeLeft">完善的账户体系支持</span>
      <div class="card-container">
        <account-card v-for="(i, idx) in accountServicesSupport" :key="idx" :name="i.name" :icon-src="i.iconSrc" v-bind="fadeLeftDelayIndex(idx)" />
      </div>
    </div>
    <div class="common">
      <span v-bind="fadeLeft">用户信息验证服务</span>
      <div class="desc" v-bind="fadeLeft">
        <p>新浪支付向合作商户提供多方位的用户身份信息验证服务，商户可以根据自身业务需要，选择适合的信息验证服务。</p>
      </div>
      <div class="card-container verify">
        <account-card v-for="(i, idx) in accountServicesInfoVerify" :key="idx" :name="i.name" :icon-src="i.iconSrc"
        v-bind="fadeLeftDelayIndex(idx)" />
      </div>
    </div>
    <div class="common advantage">
      <span v-bind="fadeLeft">账户服务产品优势</span>
      <div class="card-container">
        <payment-card v-for="(i, idx) in accountServicesAdvantages" :key="idx" :title="i.title" :sub-title="i.desc"
          :icon-src="i.iconSrc" :is-advantage="true" v-bind="fadeLeftDelayIndex(idx)" />
      </div>
    </div>
    <integration-guide />
  </div>
</template>
    
<script setup>
import TopBanner from '@c/TopBanner.vue';
import PaymentCard from './components/PaymentCard.vue';
import IntegrationGuide from '@c/IntegrationGuide.vue';
import AccountCard from './components/AccountCard.vue';
import { productions, accountServicesAdvantages, accountServicesSupport, accountServicesInfoVerify } from '@/util/enum'
import { fadeLeft, fadeLeftDelayIndex } from '@/util/aos-constants'
const accountServices = productions['accountServices']

</script>
    
<style scoped lang="scss">
.account-services {
  .common {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 80px 0 80px;

    &>span:nth-of-type(1) {
      height: 40px;
      font-size: 40px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #181A1F;
      line-height: 40px;
      align-items: center;

      &::after {
        content: '';
        width: 70px;
        height: 6px;
        display: block;
        background: #DF001F;
        margin-top: 30px;
        margin-bottom: 20px;
        margin: 30px auto 20px auto;
      }
    }

    .card-container {
      margin-top: 60px;
      width: 1280px;
      display: flex;
      // flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 0px 20px 20px 20px;
    }
  }

  .verify {
    margin-top: 0px !important;
    justify-content: flex-start !important;
  }

  .advantage {
    padding: 80px auto 40px auto;
    background: #F5F7F8;

    .card-container {
      padding-top: 0px;
    }
  }

  .desc {
    margin-top: 60px;
    width: 1240px;
    font-size: 16px;
    font-family: PingFang SC-Semibold, PingFang SC;
    color: #181A1F;
    line-height: 20px;
    align-items: center;
  }
}
</style>