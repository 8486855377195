<template>
  <div class="announcement-list">
    <TopHoverView :bannerURL="require('@/assets/mobile/announcement/announcement-banner.png')" />
    <el-tabs :model-value="category" type="card" stretch v-bind="fadeLeft" @tab-change="tabChange">
      <el-tab-pane v-for="(i, idx) in tabs" :key="idx" :label="i.name" :name="i.id" />
    </el-tabs>
    <div class="list-container">
      <div v-for="(i, idx) in tips" :key="idx" @click="clickItem(i.id)">
        <span>{{ i.title }}</span>
        <span>{{ i.createTimeFormat }}</span>
      </div>
    </div>
    <!-- <el-pagination v-model:current-page="currentPage" v-model:page-size="pageSize" prev-text="上一页" next-text="下一页"
      layout="prev, next, total" v-model:total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange"
      v-bind="fadeLeft" /> -->
    <div class="bottom-container">
      <div>
        <img :src="require(currentPage != 1? '@/assets/mobile/announcement/prev.png': '@/assets/mobile/announcement/prev-disable.png')" @click="prevPage"/>
        <img :src="require(currentPage != Math.ceil(total / 10.0)? '@/assets/mobile/announcement/next.png':'@/assets/mobile/announcement/next-disable.png')" @click="nextPage" />
      </div>
      <span>当前页：{{ currentPage }}/{{ Math.ceil(total / 10.0) }}</span>
    </div>
  </div>
</template>
     
<script setup>
import { ref } from 'vue'
import TopHoverView from '../payment/components/TopHoverView.vue'
import { qrtNoticeTop, qryCategory } from "@/API/announcement"
import get from 'lodash-es/get';
import { useRouter } from 'vue-router';

const currentPage = ref(1)
const pageSize = ref(10)
const total = ref(10)
const tips = ref([])
const tabs = ref([])
const category = ref(0)
const router = useRouter();

const queryNotice = () => {
  qrtNoticeTop({ pageNo: currentPage.value, pageSize: pageSize.value, title: '', categoryId: category.value })
    .then(res => {
      tips.value = get(res, 'data.noticeVoList', [])
      total.value = get(res, 'data.totalCount', 0)
      console.log('qrtNoticeTop res: ', res);
    })
}

qryCategory().then(res => {
  tabs.value = get(res, 'data.categoryList', [])
  category.value = tabs.value.length > 0 ? tabs.value[0].id : 0
  console.log('categoryList res: ', res);
})

const handleSizeChange = (val) => {
  console.log(`${val} items per page`)
  queryNotice()
}

const handleCurrentChange = (val) => {
  console.log(`current page: ${val}`)
  queryNotice()
}

const tabChange = (val) => {
  console.log(`tabChange change: ${val}`)
  category.value = val
  currentPage.value = 1
  console.log(`category change: ${category.value}`)
  queryNotice()
}

const clickItem = (i) => {
  router.push("/mobile/announcementDetail?id=" + i)
}

const prevPage = () => {
  if (currentPage.value != 1) {
    currentPage.value --
    queryNotice()
  }
}

const nextPage = () => {
  if (currentPage.value != Math.ceil(total.value / 10.0)) {
    currentPage.value ++
    queryNotice()
  } 
}

</script>
     
<style scoped lang="scss">
.announcement-list {
  background-color: #ffffff;

  .list-container {
    &>div {
      display: flex;
      flex-direction: column;
      align-content: center;
      border-bottom: 1px solid #EEEEEE;
      padding: 5px 30px;

      &>span:nth-of-type(1) {
        font-size: 14px;
        color: #181A1F;
      }

      &>span:nth-of-type(2) {
        margin-top: 5px;
        font-size: 12px;
        color: #5C5E62;
      }
    }
  }

  .bottom-container {
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &>div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      img {
        width: calc(50% - 10px);
        height: 40px;
      }
    }
  }

  :deep(.el-tabs__nav-scroll) {
    width: 74%;
    padding: 0 13%;
  }

  :deep(.el-tabs__item.is-active) {
    color: #181A1F;
    // color:red;
    background-color: #fff;
    border-bottom: 1px solid #F4F4F4;
  }

  :deep(.el-tabs__item) {
    color: #5C5E62;
    background-color: #F4F4F4;
  }

  :deep(.el-tabs--card>.el-tabs__header) {
    border-bottom: none;
  }

}
</style>