<!--  -->
<template>
  <div class='TopHoverView'>
    <van-image class="topBanner" :src="bannerURL" alt="" lazy-load />
    <div class="hoverTextView" :style="style">
      <p :class="describeText ? 'mediumText' : 'mediumText lessMarginP' ">{{ titleText }}</p>
      <p :class="describeText ? 'mediumText' : 'mediumText lessMarginP' " v-if='subTitleText'>{{ subTitleText }}</p>
      <p v-if="describeText" class='desc'>{{ describeText }}</p>
    </div>
  </div>
</template>

<script steup>
  export default {
    name: 'TopHoverView',
    props: {
      bannerURL: {
        type: String,
        required: true,
      },
      titleText: {
        type: String,
        required: true,
      },
      subTitleText: {
        type: String,
        required: true,
      },
      describeText: {
        type: String,
      },
      hoverTextViewTop: {
        type: String,
        default:'220px',
      }
    },
    setup( props ) {
      let style = {
        top: props.hoverTextViewTop
      }
      return {
        style
      }
    }
  }
</script>

<style scoped lang="scss">
  .topBanner {
    width: 100%;
    min-height: 300px;
  }

  .hoverTextView {
    top: 220px;
    position: absolute;
    width: 100%;
    height: 100px;

    p {
      color: #ffffff;
      margin: 10px 20%;
      white-space: pre-line;
      font-size: 20px;
    }

    .desc {
      font-size: 12px;
      line-height: 22px;
    }
  }

  .lessMarginP {
    margin: 10px 10%;
  }

  .mediumText {
    font-size: 20px;
    font-family: MiSans-Medium, PingFangSC-Medium, PingFang SC;
    color: #ffffff;
  }
</style>