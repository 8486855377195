<template>
  <div class="announcement-detail">
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item><a href="/announcement/list">公告</a></el-breadcrumb-item>
      <el-breadcrumb-item><span class="title">{{detail.title}}</span></el-breadcrumb-item>
    </el-breadcrumb>
    <div class="container" v-html="detail.content">

    </div>

    <!-- <div class="button-container">
      <span @click="prev">上一篇</span>
      <span @click="next">下一篇</span>
    </div> -->
  </div>
</template>
       
<script setup>
import { qryNoticeDetail } from "@/API/announcement"
import { onMounted, reactive } from "vue";
import { useRoute } from "vue-router";

var detail = reactive({title: '', content:''})

const route = useRoute()

const queryNotice = () => {

  // console.log('this.$route.query: ', this.$route);
  qryNoticeDetail(route.query).then(res => {
    detail.content = res.data.content 
    detail.title = res.data.title
    console.log('qrtNoticeTop res: ', res);
  })
}

onMounted(()=>{
})

queryNotice()

</script>
       
<style scoped lang="scss">
.announcement-detail {
  // width: 100%;
  padding: 120px 250px 30px 250px;
  background-color: #FAFAFA;
  // background-color: blue;

  .el-breadcrumb {
    margin: 20px auto;
  }
  .container {
    background-color: white;
    padding: 30px 30px;
    max-width: 100%;
    min-width: 1000px;
  }

  .title {
    width: 160px;
    height: 16px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #DF001F;
    line-height: 16px;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    // background-color: red;

    span {
      width: 200px;
      height: 50px;
      border-radius: 6px 6px 6px 6px;
      margin: 44px 15px;
      border: 2px solid #181A1F;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 50px;
      color: #181A1F;
      text-align: center;

      &:hover {
        background: #181A1F;
        color: #fff;
      }
    }
  }

}
</style>