<!--  -->
<template>
  <van-image :class="data.classNames" :style="data.style" :src="imageURL? imageURL : data.imageURL " alt="" lazy-load />
</template>

<script steup>
  export default {
    name: 'UseGuideView',
    props:{
      imageURL:{
        type:String,
      },
      className:{
        type:String,
      },
      marginTop:{
        type:String,
        default:'40px'
      },
      marginBottom:{
        type:String,
        default:'0px'
      },
    },
    setup(props) {
      let style = {
        marginTop:props.marginTop,
        marginBottom:props.marginBottom,
      }
      let data = {
        imageURL:require('@/assets/mobile/payment/payment_useguide@2x.png'),
        classNames:['topBanner','marginTop',props.className],
        style,
      }
     
      return {
        data
      }
    }
    
  }
</script>

<style scoped lang="scss">
  .topBanner {
    width: 100%;
    // min-height: 300px;
  }
  .marginTop {
    margin-top: 40px;
  }
</style>