<!--  -->
<template>
    <div class='AgreementList'>
      <UseGuideView :imageURL="data.bannerURL"   marginTop="0px"/>
      <div class="listContainer">
      <AgreementListItem v-for="(item,index) in data.agreementList" 
        :item="item"
        @clickEvent="(typeIndex)=>{didClickItem(index,typeIndex)}"
        :showBottomLine="index < data.agreementList.length - 1" 
        :key="item.fileEncodeName">
      </AgreementListItem>
      </div>
      
    </div>
</template>

<script steup>
import { agreements } from '@/util/enum'
import AgreementListItem from './components/AgreementListItem'
import UseGuideView from '../payment/components/UseGuideView'



export default {
  name : 'AgreementList',
  components:{AgreementListItem,UseGuideView},
  setup() {
    let data = {
      agreementList:agreements,
      bannerURL:require("@/assets/mobile/agreement/agreementBanner@2x.png"),
    }
    function didClickItem(index,typeIndex) {
      console.log('index:',index,'typeIndex:',typeIndex)
    }
    return {
      data,
      didClickItem
    }
  }
}

</script>

<style scoped lang="scss">
.listContainer {
  padding-bottom: 200px;
}

</style>