<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="solution-introduction-container">
    <div v-bind="fadeLeftDelay(100)" >
      <h3>
        {{ title }}
      </h3>
      <p v-if="desc != ''">{{ desc }}</p>
    </div>
    <img :src="require('@/' + this.imgSrc)" v-bind="fadeLeft"/>
  </div>
</template>
  
<script>
import { fadeLeft, fadeLeftDelay } from '@/util/aos-constants'
export default {
  data() {
    return {fadeLeft, fadeLeftDelay}
  },
  props: {
    title: {
      type: String,
      default: () => '方案介绍'
    },
    desc: {
      type: String,
      default: () => ''
    },
    imgSrc: {
      type: String,
      required: true,
      default: () => 'assets/solutions/accountManagement/accountManagement.png'
    },
  },
  components: {},
  mounted() {
    console.log('banner render');
  }
}
</script>
  
<style scoped lang="scss">
.solution-introduction-container {
  width: 100vw;
  height: 50vw;
  position: relative;

  &>img {
    position: absolute;
    width: 100vw;
    height: 50vw;
    top: 0;
    left: 0;
    z-index: 10;
  }

  div {
    position: absolute;
    z-index: 20;
    padding-left: 250px;
    padding-top: 10px;

    &>h3 {
      height: 40px;
      font-size: 40px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #181A1F;
      line-height: 40px;
    }

    &>p {
      max-width: 900px;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 28px;
    }
  }
}
</style>
  