<template>
  <div class="announcement-detail">
    <span>{{ detail.title }}</span>
    <div class="container" v-html="detail.content">
    </div>

    <img src="@/assets/mobile/announcement/back.png" @click="goBack"/>
  </div>
</template>
     
<script setup>
import { qryNoticeDetail } from "@/API/announcement"
import { reactive } from "vue";
import { useRouter, useRoute } from "vue-router";

var detail = reactive({ title: '', content: '' })

const route = useRoute()
const router = useRouter()

const queryNotice = () => {
  qryNoticeDetail(route.query).then(res => {
    detail.content = res.data.content
    detail.title = res.data.title
    console.log('qrtNoticeTop res: ', res);
  })
}

queryNotice()

const goBack = () => {
  router.back()
} 

</script>
     
<style scoped lang="scss">
.announcement-detail {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;

  &>span {
    color: #181A1F;
    font-size: 18px;
  }

  .container {
    background-color: white;
    margin: 30px 30px;
    width: calc(100% - 60px);
  }

  img {
    height: calc(13vw - 7px);
    width: calc(100vw - 60px);
    margin: 20px 30px;
  }
}
</style>