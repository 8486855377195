<template>
  <div class='mobileHome'>
    <div class="banner">
      <el-carousel :height="height + 'px'" arrow="never" class="el-carousel">
        <el-carousel-item v-for="item in 5" :key="item" @click="carouseClick(item)">
          <img :src="require('@/assets/mobile/home/banner-' + item + '.png')" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="product">
      <img src="@/assets/mobile/home/product-title.png" class="title" />
      <img v-for="item in 3" :src="require('@/assets/mobile/home/product-' + item + '.png')" :key="item" class="item"
        @click="productClick(item)" />
    </div>
    <div class="serve">
      <img src="@/assets/mobile/home/serve-title.png" class="title" />
      <img v-for="item in 4" :src="require('@/assets/mobile/home/serve-' + item + '.png')" :key="item" class="item" />
    </div>

    <div class="solution">
      <img src="@/assets/mobile/home/solution-title.png" class="title" />
      <el-carousel height="415px" arrow="never" class="el-carousel">
        <el-carousel-item v-for="item in 4" :key="item" @click="serveClick(item)">
          <img :src="require('@/assets/mobile/home/solution-' + item + '.png')" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="progress">
      <img src="@/assets/mobile/home/partner.png" /> 
      <img src="@/assets/mobile/home/progress.png" /> 
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
export default {

  setup() {
    const router = useRouter();
    const height = ref('1160')

    const carouseClick = (i) => {
      console.log('i: ', i);
      if (i == 1 || i == 2 || i == 3) {
        router.push('/mobile/collaboration')
      }
      // if (i == 4) {
      //   const imageURL = require('@/assets/home/banner-detail-4.jpg')
      //   router.push('/mobile/lookbigimage?imageURL=' + imageURL)
      // }

    }

    const productClick = (i) => {
      const paths = ['/mobile/standardPayment', '/mobile/accountmiddleproduct', '/mobile/accountserviceproduct']
      router.push(paths[i - 1])
    }

    const serveClick = (i) => {
      const paths = ['/mobile/accountManagement', '/mobile/internetFinance', '/mobile/electronicCommerce', '/mobile/fundPayment']
      router.push(paths[i - 1])
    }

    onMounted(() => {
      height.value = '' + (window.innerWidth * 1160 / 750)
    })

    return {
      carouseClick,
      productClick,
      serveClick,
      height
    }
  }
}


</script>

<style scoped lang="scss">
.mobileHome {
  .banner {
    height: auto;
    width: 100%;
  }

  .el-carousel {
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    :deep(.el-carousel__indicators--horizontal) {
      bottom: 50px !important;
    }
  }

  .product {
    background-color: white;
    padding: 44px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      width: 150px;
      height: 28px;
    }

    .item {
      margin-top: 20px;
      width: 343px;
      height: 120px;
    }
  }

  .serve {
    background-color: #F5F7F8;
    padding: 20px 20px;
    display: flex;
    // flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;

    .title {
      width: 341px;
      height: 28px;
    }

    .item {
      margin-top: 10px;
      width: 162px;
      height: 150px;
    }
  }

  .solution {
    background-color: #181A1F;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      width: 174px;
      height: 28px;
      margin-bottom: 20px;
    }
  }

  .progress {
    padding: 20px 20px; 
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}
</style>