<template>
  <div id="home" class="home">
    <div class="banner">
      <el-carousel :height="height + 'px'" arrow="never" class="el-carousel">
        <el-carousel-item v-for="item in 5" :key="item" @click="carouseClick(item)">
          <img :src="require('@/assets/home/banner-' + item + '.jpg')" />
        </el-carousel-item>
      </el-carousel>
      <!-- <div v-show="showTitle" class="common content" v-bind="fadeLeft">
        <p>致力成为更懂客户的<br />专业支付解决方案提供商</p>
        <el-link :underline="false" href='/collaboration' target="_blank">
          接入新浪支付
        </el-link>
      </div> -->
      <tips-component :tips="tips" class="tips" />
    </div>
    <img-title img-src='assets/home/title-1.png' :img-style="{ width: '480px' }">
      <div class="production-serve">
        <card-item v-for="(i, idx) in Object.values(productions)" :key="idx" type="product" :title="i.title"
          :sub-title="i.subTitle" :icon-src="i.iconSrc" :icon-hover-src="i.iconHoverSrc" v-bind="fadeLeftDelayIndex(idx)"
          @click="router.push(i.path)" />
      </div>
    </img-title>
    <div class="serve">
      <img src="@/assets/home/title-2.png" v-bind="fadeLeft" />
      <div>
        <card-item v-for="(i, idx) in homeServes" :key="idx" type="serve" :title="i.title" :sub-title="i.subTitle"
          :icon-src="i.iconSrc" v-bind="fadeLeftDelayIndex(idx)" />
      </div>
    </div>
    <div class="solution">
      <div class="content">
        <img src="@/assets/home/title-3.png" v-bind="fadeLeft" />
        <div class="carousel-container" v-bind="fadeLeft">
          <el-carousel height="400px" indicator-position="outside">
            <el-carousel-item v-for="(i, idx) in Object.values(solutions)" :key="idx">
              <div class="carousel-item">
                <img :src="require('@/' + i.imgSrc)" />
                <div>
                  <span>
                    {{ i.title }}
                  </span>
                  <p>
                    {{ i.desc }}
                  </p>
                  <el-link :underline="false" :href='i.path' target="_blank">
                    了解详情
                  </el-link>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div class="partner">
      <img src="@/assets/home/title-4.png" v-bind="fadeLeft" />
      <div>
        <div v-for="(i, idx) in partners" :key="idx" v-bind="fadeLeftDelayIndex(idx % 4, 100)">
          <img :src="require('@/' + i.iconSrc)" />
        </div>
      </div>
    </div>
    <div class="progress">
      <img src="@/assets/home/title-5.png" v-bind="fadeLeft" />
      <div>
        <template v-for="(i, idx) in homeProgresses" :key="idx">
          <card-item type="progress" :title="i.title" :sub-title="i.desc" :icon-src="i.iconSrc" :idx="idx + 1"
            v-bind="fadeLeftDelayIndex(idx)" />
          <img src="@/assets/home/arrow-right.png" v-if="idx + 1 < homeProgresses.length"
            v-bind="fadeLeftDelayIndex(idx)" />
        </template>
      </div>
      <el-link :underline="false" href='https://e.pay.sina.com.cn/web/register' target="_blank" v-bind="fadeLeft">
        立即接入
      </el-link>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { qrtNoticeTop } from "@/API/announcement"
import ImgTitle from '@c/ImgTitle.vue';
import TipsComponent from './components/HomeTips.vue'
import CardItem from './components/HomeCardItem.vue'
import { productions, solutions, homeServes, homeProgresses, partners } from '@/util/enum'
import { fadeLeft, fadeLeftDelayIndex } from '@/util/aos-constants'
import get from 'lodash-es/get';
import { useRouter } from 'vue-router'
const tips = ref([])
const height = ref('570')
const router = useRouter()

qrtNoticeTop({ pageNo: 1, pageSize: 5 }).then(res => {
  tips.value = get(res, 'data.noticeVoList', [])
  console.log('qrtNoticeTop res: ', res);
})

onMounted(() => {
  height.value = '' + (window.innerWidth * 1500 / 3840)
})

window.addEventListener('resize', () => {
  console.log('window.innerWidth: ', window.innerWidth);
  height.value = '' + (window.innerWidth * 1500 / 3840)
})

const carouseClick = (i) => {
  if (i == 1 || i == 2) {
    window.open('/collaboration')
  } else if (i == 4) {
    // const imageURL = require('@/assets/home/banner-detail-4.jpg')
    //window.open(`/lookbigimage?imageURL=${imageURL}`)
  }
}

</script>

<style scoped lang="scss">
.home {
  .banner {
    height: auto;
    width: 100%;
    min-width: 1344px;
    position: relative;

    .el-carousel {
      width: 100%;
      min-width: 1344px;

      img {
        width: 100%;
        height: 100%;
        min-width: 1344px;
        object-fit: cover;
      }

      :deep(.el-carousel__indicators--horizontal) {
        bottom: 50px !important;
      }
    }

    .tips {
      position: absolute;
      z-index: 100;
      min-width: 1344px;
      left: 0px;
      bottom: 0;
    }
  }

  .production-serve {
    margin-top: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .serve {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px;
    background-image: url('@/assets/home/bg-1.jpg');

    &>img {
      width: 1096px;
      height: 90px;
    }

    &>div {
      margin-top: 60px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .solution {
    padding: 60px;
    background-color: #181A1F;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;

      &>img {
        width: 560px;
        height: 90px;
        margin-bottom: 60px;
      }

      .carousel-container {
        width: 990px;

        .carousel-item {
          display: flex;
          border-radius: 50px;

          img {
            width: 440px;
            height: 400px;
          }

          div {
            width: 590px;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            padding: 40px;

            &>span {
              font-size: 24px;
              font-weight: 500;
              color: #181A1F;
              line-height: 24px;

              &::after {
                content: '';
                width: 40px;
                height: 4px;
                display: block;
                background: #DF001F;
                margin-top: 30px;
                margin-bottom: 20px;
              }
            }

            &>p {
              font-size: 16px;
              font-weight: 400;
              color: #5C5E62;
              line-height: 28px;
              white-space: pre-line;
            }

            .el-link {
              margin-top: 40px;
              text-align: center;
              border: 2px solid #181A1F;
              border-radius: 6px;
              width: 200px;
              height: 50px;
              font-size: 16px;
              color: #181A1F;
              font-weight: 400;
            }
          }
        }

        :deep(.el-carousel__indicators--outside) {
          margin-top: 30px;
        }

        :deep(.el-carousel__container) {
          border-radius: 16px;
          overflow: hidden;
        }
      }
    }
  }

  .partner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px;
    background-image: url('@/assets/home/bg-2.jpg');

    &>img {
      width: 702px;
      height: 90px;
    }

    &>div {
      margin-top: 40px;
      width: 1320px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;

      div {
        width: 240px;
        height: 60px;
        margin: 20px 35px;
        // margin-bottom: 40px;
        // border: 1px dashed black;

        img {
          width: 240px;
          height: 60px;
          border-radius: 0px 0px 0px 0px;
        }
      }
    }
  }

  .progress {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px;
    background-image: url('@/assets/home/bg-1.jpg');

    &>img {
      width: 880px;
      height: 90px;
    }

    &>div {
      margin: 60px 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 20px;
        height: 30px;
      }
    }

    .el-link {
      width: 280px;
      height: 50px;
      background: #DF001F;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      color: #fff;
    }
  }
}
</style>