<!--  -->
<template>
    <div class='AboutUS'>
      <TopHoverView 
      hoverTextViewTop="190px"
      :bannerURL="data.bannerURL"  
      titleText="新浪支付"
      subTitleText='可信赖的合作伙伴'/>
      <UseGuideView :imageURL="data.aboutUSTitle"   marginTop="0px"/>
      <UseGuideView :imageURL="data.joinUSWhyTitle" marginTop="0px"/>
      <van-image class="titleImage" :src="data.contactUsTitle" alt="" lazy-load/>
      <div class="segmentView">
        <van-button  @click="changeLoaction(0)" class="baseButton" :class="reactiveData.shanghaiClass ">上海</van-button>
        <van-button  @click="changeLoaction(1)" class="baseButton" :class="reactiveData.beijingClass ">北京</van-button>
      </div>
      <UseGuideView :imageURL="reactiveData.location"  marginTop="0px"/>
    </div>
</template>

<script steup>
import TopHoverView from '../payment/components/TopHoverView'
import UseGuideView from '../payment/components/UseGuideView'
import { reactive } from 'vue'
export default {
  name : 'AboutUS',
  components:{TopHoverView,UseGuideView},
  setup() {
    let data = {
      bannerURL:require('@/assets/mobile/aboutUS/aboutUS_banner@2x.png'),
      aboutUSTitle:require('@/assets/mobile/aboutUS/aboutUS_title@2x.png'),
      joinUSWhyTitle:require('@/assets/mobile/aboutUS/aboutUS_title_joinwhy@2x.png'),
      contactUsTitle:require('@/assets/mobile/aboutUS/aboutUS_title_contactus@2x.png'),
      shanghaiLocation:require('@/assets/mobile/aboutUS/aboutUS_location_shanghai@2x.png'),
      beijingLocation:require('@/assets/mobile/aboutUS/aboutUS_location_beijing@2x.png'),
    }
    let reactiveData = reactive({
      selectedIndex:0,
      location:data.shanghaiLocation,
      shanghaiClass:'selectedButton',
      beijingClass:'normalButton',
    })
    function changeLoaction(index) {
      if(index == reactiveData.selectedIndex) {return;}
      reactiveData.selectedIndex = index;
      reactiveData.location = (index == 0 ? data.shanghaiLocation :data.beijingLocation );
      reactiveData.shanghaiClass = (index == 0 ? 'selectedButton' : 'normalButton');
      reactiveData.beijingClass = (index == 1 ? 'selectedButton' : 'normalButton');
    }
    return {
      data,
      reactiveData,
      changeLoaction,
    }
  }
}

</script>

<style scoped lang="scss">
.segmentView {
  margin: 10px 35px;
  background: #F4F4F4;
  border-radius: 12px;
  display: flex;
  overflow: hidden;
  padding: 2px;
}
.baseButton {
  font-size: 12px;
  font-family: 'MiSans-Medium';
  width: 50%;
  margin: 0;
  border: none !important;
  border-radius: 6px;
  height: 40px;
  overflow: hidden;
}
.normalButton {
  color: #5C5E62;
  background-color: #F4F4F4;
}
.selectedButton {
  color: #181A1F;
  background-color: #FFFFFF;
}

.titleImage {
    width: 200px;
    margin:30px  calc(50% - 100px);
}

</style>