
const routes = [
  {
    path: "/solutions/accountManagement",
    name: 'accountManagement',
    component: () => import("@pages/solutions/AccountManagement"),
    meta: {
      title: '企业级账户管理',
    },
  },
  {
    path: "/solutions/internetFinance",
    name: 'internetFinance',
    component: () => import("@pages/solutions/InternetFinance"),
    meta: {
      title: '互联网金融行业',
    },
  },
  {
    path: "/solutions/electronicCommerce",
    name: 'electronicCommerce',
    component: () => import("@pages/solutions/ElectronicCommerce"),
    meta: {
      title: '电商行业',
    },
  },
  {
    path: "/solutions/fundPayment",
    name: 'fundPayment',
    component: () => import("@pages/solutions/FundPayment"),
    meta: {
      title: '基金支付',
    },
  }
]

export default routes
