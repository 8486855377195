<!--  -->
<template>
  <div class="header" :class="whiteNav ? 'white' : 'transparent'">
    <el-menu :default-active="activeIndex" :class="whiteNav ? 'white' : 'transparent'" mode="horizontal"
      :text-color="whiteNav ? '#303133' : '#fff'" :active-text-color="whiteNav ? '#303133' : '#fff'"
      :background-color="whiteNav ? '#fff' : 'transparent'" :ellipsis="false" @select="handleSelect" :router="true">
      <div class="flex-grow" />
      <!-- <el-menu-item index="/"> -->
      <img :src="require(whiteNav ? '@/assets/header/top-logo-black.png' : '@/assets/header/top-logo-white.png')"
        title="新浪支付" class="logo" />
      <!-- </el-menu-item> -->

      <template v-for="i in navMenus" :key="i.path">
        <el-menu-item v-if="i.subs.length == 0" :index="i.path">{{ i.label }}</el-menu-item>
        <el-sub-menu v-else :index="i.path">
          <template #title>{{ i.label }}</template>
          <el-menu-item v-for="(sub) in i.subs" :index="sub.path" :key="sub.path" class="popup-item">{{ sub.label
          }}</el-menu-item>
        </el-sub-menu>
      </template>
      <div class="flex-grow" />
      <el-link :underline="false" href='https://e.pay.sina.com.cn/site/login' target="_blank">
        商户服务入口
      </el-link>
    </el-menu>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { onBeforeRouteUpdate } from 'vue-router'
import { navMenus } from '@/util/enum'
// import { useRouter } from 'vue-router'
// import { useCounterStore1 } from '@/stores'
// import { storeToRefs } from 'pinia'

export default {
  name: 'HeaderComponent',

  setup() {
    // const router = useRouter()
    // const store = useCounterStore1()
    // const doubleValue = computed(() => store.double)

    // const { count } = storeToRefs(store)
    // const { increment } = store
    var activeIndex = 'home'
    const subPages = ['/announcement/detail']
    var whiteNav = ref(subPages.includes(window.location.pathname))
    // var router = useRouter()
    onMounted(() => {
      // console.log('header mounted');
    })

    onBeforeRouteUpdate((to, from) => {
      whiteNav.value = subPages.includes(to.fullPath)
      // console.log('isSubPage: ', isSubPage.value);
      // console.log('to: ', to, ' from: ', from);
    })

    function handleSelect(key, keyPath) {
      console.log('key: ', key, 'keyPath: ', keyPath);
      if (key != activeIndex) {
        activeIndex = key
      }

      if (key == 'https://wallet.weibopay.com') {
        window.open(keyPath)
      }
    }

    window.addEventListener('scroll', () => {
      console.log('scroll');
      const offset = window.scrollY || document.documentElement.scrollTop
      console.log('yOffset: ', offset);
      whiteNav.value = offset > 80 || subPages.includes(window.location.pathname)
    })

    return {
      handleSelect,
      navMenus,
      activeIndex,
      whiteNav
    }
  }
}
</script>

<style scoped>
.header {
  position: fixed;
  height: 80px;
  width: 100vw;
  min-width: 1200px;
}

.transparent {
  background-color: transparent !important;
}

.white {
  background-color: white !important;
}

.el-menu {
  padding: 15px 64px;
  align-items: center;
}

.el-menu--horizontal {
  border: none;
}

.popup-item {
  --el-menu-text-color: #181A1F;
  --el-menu-active-color: #181A1F;
  --el-menu-hover-text-color: #181A1F;
}

.logo {
  height: 34px;
  width: 136px;
  margin-right: 40px;
}

.el-link {
  width: 120px;
  height: 40px;
  background: #DF001F;
  opacity: 1;
  text-align: center;
  border-radius: 4px;
  margin-right: 150px;
  font-size: 14px;
  color: #FFFFFF;
}

.el-link:hover {
  color: #FFFFFF;
}

.flex-grow {
  flex-grow: 1;
}
</style>