<template>
  <div class="fund-payment">
    <top-banner :title="fundPayment.title" :subTitle="fundPayment.desc" :bgSrc="fundPayment.bgSrc" />
    <div class="middle-container">
      <div>
        <h3 v-bind="fadeLeft">行业现状及痛点</h3>
        <div class="content">
          <solution-card v-for="(i, idx) in fundPaymentPainPoints" :key="idx" type="fundPaymentPainPoints" :name="i.title"
            :desc="i.desc" :icon-src="i.iconSrc" :class="{ 'right-border': idx != 2 }" v-bind="fadeLeftDelayIndex(idx)" />
        </div>
      </div>
    </div>
    <div class="middle-container">
      <div>
        <h3 v-bind="fadeLeft">我们的优势</h3>
        <div class="content">
          <solution-card v-for="(i, idx) in fundPaymentAdvantages" :key="idx" type="fundPaymentAdvantages" :name="i.title"
            :desc="i.desc" :icon-src="i.iconSrc" v-bind="fadeLeftDelayIndex(idx)" />
        </div>
      </div>
    </div>
    <solution-introduction img-src="assets/solutions/fundPayment/fundPayment.png" />
    <integration-guide />
  </div>
</template>
 
<script setup>
import TopBanner from '@c/TopBanner.vue';
import IntegrationGuide from '@c/IntegrationGuide.vue';
import SolutionCard from './components/SolutionCard.vue';
import SolutionIntroduction from './components/SolutionIntroduction.vue';
import { solutions, fundPaymentPainPoints, fundPaymentAdvantages } from '@/util/enum'
import { fadeLeft, fadeLeftDelayIndex } from '@/util/aos-constants'
const fundPayment = solutions['fundPayment']
</script>
 
<style scoped lang="scss">
.fund-payment {
  .middle-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

    &>div {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
      width: calc(100% - 500px);
      max-width: 1200px;
      height: 100%;
      padding: 40px;
      border-left: 1px solid #E6E6E9;
      border-right: 1px solid #E6E6E9;

      &>h3 {
        height: 40px;
        font-size: 40px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #181A1F;
        line-height: 40px;
      }

      .content {
        display: flex;
        flex-direction: row;
        padding-top: 40px;
        padding-left: 20px;
        flex-wrap: wrap;
        justify-content: space-between;

        .right-border {
          border-right: 1px solid #E6E6E9;
        }
      }
    }
  }
}</style>