<template>
  <div class="internet-finance ">
    <top-banner :title="internetFinance.title" :subTitle="internetFinance.desc" :bgSrc="internetFinance.bgSrc" />
    <div class="middle-container">
      <div>
        <h3 v-bind='fadeLeft'>
          资金方直接参与资金发放
        </h3>
        <div class="strategy">
          <solution-card v-for="(i, idx) in internetFinanceMethods" type="internetFinanceMethods" :key="idx"
            :name="i.title" :desc="i.desc" :icon-src="i.iconSrc" v-bind="fadeLeftDelayIndex(idx)" />
        </div>
      </div>
    </div>
    <div class="middle-container gray-bg">
      <div>
        <h3 v-bind='fadeLeft'>
          还款流程中新浪支付提供的服务
        </h3>
        <div class="strategy">
          <solution-card v-for="(i, idx) in internetFinanceServices" type="internetFinanceStrategy" :key="idx"
            :name="i.title" :desc="i.desc" :icon-src="i.iconSrc" v-bind="fadeLeftDelayIndex(idx)"/>
        </div>
      </div>
    </div>
    <div class="middle-container">
      <div>
        <h3 v-bind='fadeLeft'>
          全面、灵活的手续费及开票流程
        </h3>
        <div class="authorization">
          <solution-card v-for="(i, idx) in internetFinanceProgress" type="internetFinanceProgress" :key="idx"
            :name="i.title" :desc="i.desc" :icon-src="i.iconSrc" v-bind="fadeLeftDelayIndex(idx)" />
        </div>
      </div>
    </div>
    <solution-introduction img-src="assets/solutions/internetFinance/internetFinance.png" />
    <integration-guide />
  </div>
</template>
 
<script setup>
import TopBanner from '@c/TopBanner.vue';
import IntegrationGuide from '@c/IntegrationGuide.vue';
import SolutionCard from './components/SolutionCard.vue';
import SolutionIntroduction from './components/SolutionIntroduction.vue';
import { solutions, internetFinanceMethods, internetFinanceServices, internetFinanceProgress } from '@/util/enum'
import { fadeLeft, fadeLeftDelayIndex } from '@/util/aos-constants'
const internetFinance = solutions['internetFinance']
</script>
 
<style scoped lang="scss">
.internet-finance {
  .middle-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &>div {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
      width: calc(100% - 500px);
      max-width: 1200px;
      height: 100%;
      padding: 40px;
      border-left: 1px solid #E6E6E9;
      border-right: 1px solid #E6E6E9;

      &>h3 {
        height: 40px;
        font-size: 40px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #181A1F;
        line-height: 40px;
      }

      &>span {
        max-width: 1000px;
        height: 30px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #181A1F;
        line-height: 30px;
        margin-bottom: 30px;
      }

      .strategy {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 40px;
      }

      .authorization {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-top: 40px;
        padding-left: 20px;
      }
    }
  }

  .gray-bg {
    background-color: #F5F7F8;
    height: 540px;
  }
}
</style>