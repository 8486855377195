<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="container" :class="{ advantage: isAdvantage }">
    <img :src="require('@/' + this.iconSrc)" />
    <span>{{ title }}</span>
    <span>{{ subTitle }}</span>
  </div>
</template>
  
<script>
export default {
  data() {
    return { imgPath: this.iconSrc }
  },
  props: {
    title: {
      type: String,
      required: true,
      default: () => '储蓄卡快捷支付'
    },
    subTitle: {
      type: String,
      required: true,
      default: () => '消费者只需提供储蓄卡卡号、户名、手机动态码等卡信息及身份认证信息后，即可完成付款、充值、提现等功能。'
    },
    iconSrc: {
      type: String,
      required: true,
      default: () => 'assets/home/folder.png'
    },
    isAdvantage: {
      type: Boolean,
      default: () => false
    }
  },

  components: {},
  computed: {
  },
  mounted() {
    console.log('product card render: ', this.type);
  },
  methods: {
    // ttt() {

    //   console.log('is hover:', this.isHover);
    // }
  }

}
</script>
  
<style scoped lang="scss">
.container {
  width: 196px;
  height: 310px;
  background: #F5F7F8;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 20px;
  padding: 10px 40px;
  text-align: center;

  &>img {
    width: 120px;
    height: 120px;
  }

  &>span:nth-of-type(1) {
    color: #181A1F;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    margin: 30px 0 15px 0;
  }

  &>span:nth-of-type(2) {
    font-size: 14px;
    color: #5C5E62;
    line-height: 22px;
  }
}

.advantage {
  width: 320px;
  padding: 10px 0px !important;
}
</style>
  