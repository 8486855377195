<!--  -->
<template>
  <el-container>
    <el-header>
      <Header />
    </el-header>
    <el-main>
      <router-view></router-view>
      <el-backtop :right="50" :bottom="100" @mouseover="this.topSrc = 'assets/to-top-black.png'"
          @mouseout="this.topSrc = 'assets/to-top-white.png'">
        <img :src="require('@/' + this.topSrc)" />
      </el-backtop>
    </el-main>
    <el-footer>
      <Footer />
    </el-footer>
  </el-container>
</template>

<script>

import Header from '@c/Header'
import Footer from '@c/Footer'

export default {
  name: 'WebLayout',
  data() {
    return { topSrc: 'assets/to-top-white.png' }
  },
  components: { Header, Footer },
}


</script>

<style scoped>
.el-container {
  margin: -1px;
}

.el-header {
  height: 84px;
  z-index: 999;
}

.el-footer {
  padding: 20px 120px;
}

.el-header {
  padding: 0;
}

.el-footer {
  height: 600px;
  background-color: #222222;
}

.el-main {
  padding: 0;
  margin-top: -84px;

  .el-backtop {
    z-index: 99;
  }
}
</style>