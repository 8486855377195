<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="container">
    <span>阶段</span>
    <span>{{ step }}</span>
  </div>
</template>
  
<script>
export default {
  data() {
    return { imgPath: this.iconSrc }
  },
  props: {
    step: {
      type: String,
      default: () => '1'
    }
  }
}
</script>
  
<style scoped lang="scss">
.container {
  width: 110px;
  height: 110px;
  // background: #F5F7F8;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #EEEEEE;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: auto;
  text-align: center;

  &>span:nth-of-type(1) {

    color: #181A1F;
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    // margin: 30px 0 15px 0;
  }

  &>span:nth-of-type(2) {
    font-size: 60px;
    font-weight: bold;
    color: #181A1F;
    line-height: 70px;
  }
}

</style>
  