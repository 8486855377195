<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="title-container">
    <img :src="require('@/' + this.imgSrc)" :style="this.imgStyle" v-bind="fadeLeft" />
    <slot>
      默认内容
    </slot>
  </div>
</template>
    
<script>
import { fadeLeft } from '@/util/aos-constants'
export default {

  data() {
    return { imgPath: this.iconSrc, fadeLeft }
  },
  props: {
    imgSrc: {
      type: String,
      required: true,
      default: () => ''
    },
    imgStyle: {
      type: Object,
      required: true,
      default: () => { }
    },
    containerStyle: {
      type: Object,
      required: false,
      default: () => { }
    }
  }
}
</script>
    
<style scoped lang="scss">
.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;
  // background-image: url('@/assets/home/bg-2.jpg');
  // :style="{ backgroundImage: 'url(' + '@/assets/home/bg-2.jpg' + ')' }"

  &>img {
    height: 90px;
  }
}
</style>
    