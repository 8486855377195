<template>
  <div class="account-management">
    <top-banner :title="accountManagement.title" :subTitle="accountManagement.desc" :bgSrc="accountManagement.bgSrc" />
    <div class="middle-container">
      <div v-bind="fadeLeft">
        <h3>多维的数据，助您有效提高收益</h3>
        <span>您可通过订单查询、数据导出、数据概览等功能，分析当前的支付趋势和用户行为，及时调整销售策略，提高收益。</span>
      </div>
    </div>
    <div class="middle-container gray-bg">
      <div>
        <h3 v-bind="fadeLeft">灵活的策略，助您解决资金周转难题</h3>
        <div class="strategy">
          <solution-card v-for="(i, idx) in accountManagementStrategies" :key="idx" type="accountManagementStrategy"
            :name="i.title" :desc="i.desc" :icon-src="i.iconSrc" v-bind="fadeLeftDelayIndex(idx)" />
        </div>
      </div>
    </div>
    <div class="middle-container">
      <div>
        <h3 v-bind="fadeLeft">严谨的管理，助您按需划分业务权限</h3>
        <div class="authorization">
          <solution-card v-for="(i, idx) in accountManagementAuthorizations" :key="idx"
            type="accountManagementAuthorization" :name="i.title" :desc="i.desc" :icon-src="i.iconSrc"
            :bottom-img-scr="i.bottomImgSrc" v-bind="fadeLeftDelayIndex(idx)" />
        </div>
      </div>
    </div>
    <solution-introduction img-src="assets/solutions/accountManagement/accountManagement.png" />
    <integration-guide />
  </div>
</template>
 
<script setup>
import TopBanner from '@c/TopBanner.vue';
import IntegrationGuide from '@c/IntegrationGuide.vue';
import SolutionCard from './components/SolutionCard.vue';
import SolutionIntroduction from './components/SolutionIntroduction.vue';
import { solutions, accountManagementStrategies, accountManagementAuthorizations } from '@/util/enum'
import { fadeLeft, fadeLeftDelayIndex } from '@/util/aos-constants'

const accountManagement = solutions['accountManagement']
</script>
 
<style scoped lang="scss">
.account-management {
  .middle-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

    &>div {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
      width: calc(100% - 500px);
      max-width: 1200px;
      height: 100%;
      padding: 40px;
      border-left: 1px solid #E6E6E9;
      border-right: 1px solid #E6E6E9;

      &>h3 {
        height: 40px;
        font-size: 40px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #181A1F;
        line-height: 40px;
      }

      &>span {
        max-width: 1000px;
        height: 30px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #181A1F;
        line-height: 30px;
        margin-bottom: 30px;
      }

      .strategy {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 40px;
      }

      .authorization {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 40px;
        padding-left: 20px;
      }
    }
  }

  .gray-bg {
    background-color: #F5F7F8;
    height: 540px;
  }
}
</style>