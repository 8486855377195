<!--  -->
<template>
  <div class='StandardPayment'>
    <TopHoverView 
      :bannerURL="data.bannerURL" 
      titleText="标准支付产品" 
      describeText="适配主流设备，覆盖各类网络，提供完善的支付服务"/>
    <van-image class="titleImage" :src="data.receiveTitle" alt="" lazy-load/>
    <van-grid :border="false" :column-num="2" >
      <van-grid-item v-for="(item) in data.receiveCards" :key="item">
        <div class="gridItem">
          <van-image  :src="item" alt="" lazy-load/>
        </div>
      </van-grid-item>
    </van-grid>

    <van-image class="titleImage" :src="data.paymentTitle" alt="" lazy-load/>
    <van-grid :border="false" :column-num="2" >
      <van-grid-item v-for="(item) in data.paymentsCards" :key="item">
        <div class="gridItem">
          <van-image  :src="item" alt="" lazy-load/>
        </div>
      </van-grid-item>
    </van-grid>
    <UseGuideView :imageURL="data.paymentSinapayAdvantages"/>
    <div class="margin-horizontal">
      <UseGuideView />
    </div>
    
  </div>
</template>

<script steup>
  import { reactive } from 'vue'  
  import TopHoverView from './components/TopHoverView'
  import UseGuideView from './components/UseGuideView'
  export default {
    name: "MobilStandardPayment",
    components:{TopHoverView,UseGuideView},
    setup() {
      let data = reactive({
        bannerURL:require('@/assets/mobile/payment/payment_banner@2x.png'),
        receiveCards:[
          require('@/assets/mobile/payment/payment_bankcard@2x.png'),
          require('@/assets/mobile/payment/payment_creditcard@2x.png'),
          require('@/assets/mobile/payment/payment_creditcard_period@2x.png'),
          require('@/assets/mobile/payment/payment_businesswithhold@2x.png'),
          require('@/assets/mobile/payment/payment_wxaliupop@2x.png'),
          require('@/assets/mobile/payment/payment_persononline@2x.png'),
          require('@/assets/mobile/payment/payment_merchantonline@2x.png'),
        ],
        receiveTitle:require('@/assets/mobile/payment/payment_title_receive@2x.png'),
        paymentTitle:require('@/assets/mobile/payment/payment_title@2x.png'),
        paymentsCards:[
          require('@/assets/mobile/payment/payment_personcard@2x.png'),
          require('@/assets/mobile/payment/payment_merchantcard@2x.png'),
          require('@/assets/mobile/payment/payment_sinapayaccount@2x.png'),
        ],
        paymentSinapayAdvantages:require('@/assets/mobile/payment/payment_sinapayadvantages@2x.png'),
        paymentUseGuide:require('@/assets/mobile/payment/payment_useguide@2x.png')
      })
      return {
        data
      };
    }
  }
</script>

<style scoped lang="scss">
  .StandardPayment {
    background-color: #ffffff;
    position: relative;
    overflow: hidden;
    padding-bottom:100px;
    .topBanner {
      width: 100%;
      min-height: 300px;
    }
    .marginTop {
      margin-top: 40px;
    }

    .van-grid-item {
      margin: 0px;
    }
    :deep(.van-grid-item__content) {
      padding:8px 15px;
    }

    .margin-horizontal {
      padding: 0px 15px;
    }
  }

  .hoverTextView {
    top: 150px;
    position: absolute;
    width: 100%;
    height: 100px;
    p {
        color: 12px;
        color: #ffffff;
        margin: 10px 14%;
      }
    .mediumText {
        font-size: 20px;
        font-family: MiSans-Medium, PingFangSC-Medium, PingFang SC;
        color: #ffffff;
    }
  }

  .titleImage {
    width: 70px;
    margin:30px  calc(50% - 35px);
  }

  .gridItem {
      min-height: 100px;
      width: 100%;
      background-color: #F5F7F8;
  }
</style>