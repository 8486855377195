<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div :class="{ 'banner-center': this.alignType == 'center', 'banner': this.alignType == 'left' }">
    <img :src="require('@/' + bgSrc)" />
    <div v-bind="fadeLeft">
      <p>{{ title }}</p>
      <span v-if="subTitle != ''">{{ subTitle }}</span>
    </div>
  </div>
</template>

<script>
import { fadeLeft } from '@/util/aos-constants'
export default {
  data() {
    return { fadeLeft }
  },
  props: {
    title: {
      type: String,
      required: true,
      default: () => ''
    },
    subTitle: {
      type: String,
      default: () => ''
    },
    bgSrc: {
      type: String,
      required: true,
      default: () => 'assets/productCenter/standardPayment/banner-3.jpg'
    },
    alignType: {
      type: String,
      default: () => 'left'
    }
  },
  components: {},
  mounted() {
    console.log('banner render');
  }
}
</script>

<style scoped lang="scss">
.banner {
  position: relative;
  width: 100%;
  min-width: 1344px;
  height: calc(100vw * 100 / 384);

  img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 1344px;
    width: 100%;
    height: calc(100vw * 100 / 384);
    z-index: 10;
  }

  div {
    margin-left: 240px;
    z-index: 20;
    background-color: transparent;
    max-width: 470px;
    height: calc(100vw * 100 / 384 - 90px);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    p {
      font-size: 40px;
      color: #FFFFFF;
      letter-spacing: 2px;
      text-align: left;
      line-height: 70px;
      margin-top: 100px;
      margin-bottom: 12px;
    }

    span {
      // max-width: 200px;
      font-size: 16px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 28px;
      white-space: pre-line;
    }
  }
}

.banner-center {
  position: relative;
  width: 100%;
  height: calc(100vw * 100 / 384);


  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vw * 100 / 384);
    z-index: 10;
  }

  div {
    padding: 60px 200px 60px -200px;
    z-index: 20;
    background-color: transparent;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    p {
      font-size: 40px;
      color: #FFFFFF;
      letter-spacing: 2px;
      text-align: center;
      line-height: 70px;
    }

    span {
      font-size: 16px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 28px;
      white-space: pre-line;
    }
  }
}
</style>
