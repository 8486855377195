import { defineStore } from 'pinia'
import { ref } from 'vue'
// options store
export const useCounterStore = defineStore('counter', {
  // state 是 store 的数据 (data)，getters 是 store 的计算属性 (computed)，而 actions 则是方法 (methods)
  state: () => ({ count: 0 }),
  getters: {
    double: (state) => state.count * 2,
  },
  actions: {
    increment() {
      this.count++
    }
  }
})

// setup store

export const useCounterStore1 = defineStore('counter1', () => {
  const count = ref(0)
  function increment() {
    count.value++
  }

  return { count, increment }
})
