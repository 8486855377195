<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="item-container">
    <div>
      <span>{{ title }}</span>
      <img src="@/assets/about/line.png" />
    </div>
    <p>{{ desc }}</p>
  </div>
</template>
    
<script>
export default {
  data() {
    return {}
  },
  props: {
    title: {
      type: String,
      required: true,
      default: () => ''
    },
    desc: {
      type: String,
      required: true,
      default: () => ''
    }
  },

  components: {},
  computed: {
  },
  mounted() {
  },
  methods: {
    // ttt() {

    //   console.log('is hover:', this.isHover);
    // }
  }

}
</script>
    
<style scoped lang="scss">
.item-container {
  padding-bottom: 30px;

  &>div {
    display: inline-flex;
    flex-direction: column;
    height: 24px;

    span {
      z-index: 10;
      height: 24px;
      font-size: 24px;
      width: auto;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #181A1F;
      line-height: 24px;
    }

    img {
      height: 6px;
      margin-top: -4px;
    }
  }

  p {
    height: 70px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #5C5E62;
    line-height: 24px;
  }
}
</style>
    