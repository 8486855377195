<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="container">
    <img :src="require('@/' + this.iconSrc)" />
    <span>{{ name }}</span>
  </div>
</template>
    
<script>
export default {
  data() {
    return { imgPath: this.iconSrc }
  },
  props: {
    name: {
      type: String,
      required: true,
      default: () => '储蓄卡快捷支付'
    },
    iconSrc: {
      type: String,
      required: true,
      default: () => 'assets/home/folder.png'
    }
  },

  components: {},
  computed: {
  },
  mounted() {
    // console.log('product card render: ', this.type);
  },
  methods: {
    // ttt() {

    //   console.log('is hover:', this.isHover);
    // }
  }

}
</script>
    
<style scoped lang="scss">
.container {
  width: 276px;
  height: 260px;
  background: #F5F7F8;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  text-align: center;

  &>img {
    width: 120px;
    height: 120px;
  }

  &>span:nth-of-type(1) {
    color: #181A1F;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    margin: 30px auto;
  }
}
</style>
    