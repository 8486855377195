<!--  -->
<template>
  <div class="header">
    <div class="mobile-container">
      <img src="@/assets/header/top-logo-black.png" title="新浪支付" class="logo" />
      <img :src="require(showCollapseMenu?'@/assets/mobile/home/navi-menu-close.png':'@/assets/mobile/home/navi-menu.png')" class="menu" @click="showMenuClick" />
    </div>
    <el-collapse v-if="showCollapseMenu">
      <el-collapse-item v-for="i in mobileNavMenus" :key="i.path" :title="i.label" :name="i.path" @click="clickCollapseItem(i)">
        <div v-for="(sub, idx) in i.subs" :key="sub.label" class="sub-menu-item" :class="{'sub-menu-item-first':idx == 0}">
          <el-link :underline="false" :href='sub.path'>
            {{ sub.label }}
          </el-link>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
  
<script>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { mobileNavMenus } from '@/util/enum'
export default {
  name: 'HeaderComponent',

  setup() {
    var showCollapseMenu = ref(false);
    var router = useRouter()
    onMounted(() => {
      // console.log('header mounted');
    })

    function showMenuClick() {
      showCollapseMenu.value = !showCollapseMenu.value
    }

    function clickCollapseItem(i) {
      if (i.subs.length == 0) {
        console.log('click path: ', i.path);
        showCollapseMenu.value = !showCollapseMenu.value
        if (i.path == '/mobile/aboutus') {
          router.replace(i.path)
        } else {
          window.location.href = i.path
        }
      }
    }

    return {
      mobileNavMenus,
      showCollapseMenu,
      showMenuClick,
      clickCollapseItem
    }
  }
}
</script>
  
<style scoped>
.header {
  position: fixed;
  height: 50px;
  width: 100vw;
  z-index: 999;
  /* top: 0;
    left: 0; */
  /* min-width: 1200px; */
}

.logo {
  height: 34px;
  width: 136px;
  margin-right: 40px;
}

.menu {
  width: 28px;
  height: 28px;
  /* margin-right: 20px; */
}

.mobile-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
  height: 50px;
  background-color: white;
}

.sub-menu-item {
  padding-bottom: 15px;
}

.sub-menu-item-first {
  padding-top: 5px;
}

:deep(.el-collapse-item__header) {
  padding-left: 15px;
}

:deep(.el-collapse-item__content) {
  padding-bottom: 0px;
}

.transparent {
  background-color: transparent !important;
}

.white {
  background-color: white !important;
}

.el-link {
  padding-left: 30px;
  font-size: 12px;
  color: #181A1F;
}
</style>