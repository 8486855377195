<!--  -->
<template>
    <div class='MobileLayout'>
      <MobileHeader/>
      <div class="mainContent">
        <router-view/>
      </div>
      <MobileFooter/>
    </div>
</template>

<script steup>
import MobileFooter from '@c/mobile/MobileFooter'
import MobileHeader from '@c/mobile/MobileHeader'


export default {
  name :'MobileLayout',
  components:{ MobileFooter, MobileHeader }
}
</script>

<style lang="scss">
.MobileLayout {
  .mainContent {
    padding-top: 50px;
    min-height: calc(100vh - 373px);
  }
  height: 100%;
  p {
    margin: 0;
    padding: 0;
    text-align: center;
  }


}

</style>