<template>
  <div class="standard-payment">
    <top-banner :title="standardPayment.title" :subTitle="standardPayment.subTitle" :bgSrc="standardPayment.bgSrc" />
    <div class="common">
      <span v-bind="fadeLeft">收款类</span>
      <div class="card-container">
        <payment-card v-for="(i, idx) in standardPaymentReceipts" :key="idx" :title="i.title" :sub-title="i.desc"
          :icon-src="i.iconSrc" v-bind="fadeLeftDelayIndex(idx % 4, 200)" />
      </div>
    </div>
    <div class="common payment">
      <span v-bind="fadeLeft">付款类</span>
      <div class="card-container">
        <payment-card v-for="(i, idx) in standardPaymentPayments" :key="idx" :title="i.title" :sub-title="i.desc"
          :icon-src="i.iconSrc" v-bind="fadeLeftDelayIndex(idx)" />
      </div>
    </div>
    <div class="common advantage">
      <span v-bind="fadeLeft">充分利用新浪支付的优势</span>
      <div class="card-container">
        <payment-card v-for="(i, idx) in standardPaymentAdvantages" :key="idx" :title="i.title" :sub-title="i.desc"
          :icon-src="i.iconSrc" :is-advantage="true" v-bind="fadeLeftDelayIndex(idx)" />
      </div>
    </div>
    <integration-guide />
  </div>
</template>
 
<script setup>
import TopBanner from '@c/TopBanner.vue';
import PaymentCard from './components/PaymentCard.vue';
import IntegrationGuide from '@c/IntegrationGuide.vue';
import { productions, standardPaymentReceipts, standardPaymentPayments, standardPaymentAdvantages } from '@/util/enum'
import { fadeLeft, fadeLeftDelayIndex } from '@/util/aos-constants'
const standardPayment = productions['standardPayment']

</script>
 
<style scoped lang="scss">
.standard-payment {
  .common {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 80px 0 80px;

    &>span {
      height: 40px;
      font-size: 40px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #181A1F;
      line-height: 40px;
      align-items: center;

      &::after {
        content: '';
        width: 70px;
        height: 6px;
        display: block;
        background: #DF001F;
        margin-top: 30px;
        margin-bottom: 20px;
        margin: 30px auto 20px auto;
      }
    }

    .card-container {
      margin-top: 60px;
      width: 1280px;
      display: flex;
      // flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 20px;
    }
  }

  .payment {
    padding: 80px 80px;
  }

  .advantage {
    padding: 80px auto 40px auto;
    background: #F5F7F8;

    .card-container {
      margin-top: 0px;
    }
  }
}
</style>