import { createApp } from 'vue'
import App from './App.vue'
import {Button,Cell, CellGroup,Grid, GridItem,Image as VanImage,Lazyload} from 'vant';
import 'vant/lib/index.css';
import router from './router'
// import store from './store'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/dist/locale/zh-cn.mjs'

import { createPinia } from 'pinia'
import AOS from 'aos'
import 'aos/dist/aos.css'


const pinia = createPinia()
const app = createApp(App)
const aos = new AOS.init({ offset: 0, duration: 800, once: true, anchorPlacement: 'top-center', initClassName: 'sina-pay-init', animatedClassName: 'sina-pay-animate' })
app.use(aos)
app.use(Button)
app.use(Cell)
app.use(CellGroup)
app.use(Grid)
app.use(GridItem)
app.use(VanImage)
app.use(Lazyload)
// app.use(store)
app.use(pinia)
app.use(router)

app.use(ElementPlus, { locale })
app.config.globalProperties.$ELEMENT = {
    locale: locale
}
app.mount('#app')

