<template>
  <router-view />
</template>

<script>
  import {onMounted} from 'vue'
  import { useRouter,useRoute } from 'vue-router'
  import {isMobile} from '@/util/tool'
  export default {
    name: 'App',
    setup() {
      const router = useRouter();
      onMounted( () => {
        const currentPath = window.location.pathname;
        console.log('currentPath:',currentPath,currentPath.length)
        if ( isMobile() ) {
          //手机端 (直接输入URL/刷新页面,重新定向到首页问题)
          currentPath.indexOf('/mobile') < 0 && router.replace( "/mobile" )
        } else {
          // 
          currentPath.length < 2 || currentPath.indexOf('/mobile') > -1 &&  router.replace( "/" )
        }
      } )
    }
  }
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* text-align: center; */
    color: #2c3e50;
    width: 100%;
  }

  .el-menu--collapse .el-menu .el-submenu,
  .el-menu--popup {
    min-width: 120px !important;
  }
  .noTopMargin {
    margin-top: 0 !important;
  }
</style>